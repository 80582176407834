import { Box, Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';

type PhoneNumberProps = {
    onSubmit: (value: string) => void,
    loading: boolean
}

/**
  * Formatage du numéro de téléphone 
  */
const formatToPhoneNumber = (phNumber: string) => {

    // Suppression des espaces
    phNumber = phNumber.replace(/\s/g, '');

    // Internationalisation si nécessaire
    if (phNumber.startsWith("0")) {
        phNumber = "+33".concat(phNumber.substr(1, 9));
    }

    return phNumber
}

/**
 * Récupération du numéro de téléphone servant pour l'authentification
 */
const PhoneNumber = ({ onSubmit, loading }: PhoneNumberProps) => {
    // const [loading, setLoading] = useState<boolean>(false)
    const [value, setValue] = useState<string>("");

    const handleChange = (event: any) => {
        setValue(event.target.value);
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        onSubmit(formatToPhoneNumber(value))
    }

    return (
        <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        type="text"
                        value={value}
                        onChange={handleChange}
                        label="Numéro de téléphone"
                        fullWidth />
                </Grid>
                <Grid item xs={12}>
                    {/* {loading ?
                        <Box width='100%' height="150px" display='flex' justifyContent='center' alignItems='center'>
                            <CircularProgress color="secondary" />
                        </Box> : */}
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth>{loading ? <CircularProgress color="secondary" /> : "Recevoir un code par SMS"}</Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default PhoneNumber;