import React from 'react';
import { useParams } from 'react-router-dom';

import { Grid, Typography, Divider, Box, CircularProgress, Container, CardContent } from '@material-ui/core';

import { useReservation } from '../../services/firebase'

import KlikHeader from '../../components/organisms/KlikHeader'
import KlikCard from '../../components/molecules/KlikCard'

import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as FromToIcon } from '../../components/svg/FromToIcon.svg';

import { DateTime } from 'luxon';

import { toFormatDate, toFormatHours } from '../book/BookResume';

const useStyles = makeStyles({
    bold: {
        fontWeight: 'bold'
    },
    hourLabel: {
        fontWeight: 'bold',
        fontSize: 20
    }
});

function Receipt() {
    const params: any = useParams()
    const { loading, error, reservation } = useReservation({
        id: params.id
    })
    const start = DateTime.fromJSDate(reservation?.startDate!)
    const end = DateTime.fromJSDate(reservation?.endDate!)


    const classes = useStyles()


    const Row = ({ left, right, bold = false }: any) => {
        return (
            <Grid container>
                <Grid item xs={8}>
                    <Typography align="left" className={bold ? classes.bold : ''}>{left}</Typography></Grid>
                <Grid item xs={4}>
                    <Typography align="right" className={bold ? classes.bold : ''}>{right}</Typography></Grid>
            </Grid>
        )
    }

    return (
        <Container>
            <KlikHeader title="Votre réservation" back={"/reservations/"} />
            <KlikCard>
                <CardContent>
                    <Box mb={3}>
                        <Typography align="center" className={classes.bold}>Récapitulatif</Typography>
                    </Box>
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography align="center">{toFormatDate(start)}</Typography>
                            <Typography align="center" className={classes.hourLabel}>{toFormatHours(start)}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Box display="flex" alignContent="center" alignItems="center" height="100%" width="100%">
                                <Box margin="auto">
                                    <FromToIcon />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography align="center">{toFormatDate(end)}</Typography>
                            <Typography align="center" className={classes.hourLabel}>{toFormatHours(end)}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardContent>

                    <Box mb={3}>
                        <Typography align="center" className={classes.bold}>Tarif de votre réservation</Typography>
                    </Box>
                    {loading && <Box width='100%' height="150px" display='flex' justifyContent='center' alignItems='center'>
                        <CircularProgress />
                    </Box>}
                    {reservation !== undefined && <Box>
                        <Row
                            left={"Prix de la location (" + reservation.pricing.totalRentDurationHours + "h)"}
                            right={reservation.pricing.basePrice + "€"} />
                        {reservation.pricing.freeHours > 0 &&
                            <Row
                                left={"Durée de gratuité (" + reservation.pricing.freeHours + "h)"}
                                right={"-" + reservation.pricing.freeHoursAmount + "€"} />
                        }

                        {reservation.pricing.withInsurance && <Row
                            left={"Frais d'assurance (" + reservation.pricing.insuranceDuration + "j)"}
                            right={reservation.pricing.insurancePrice + "€"} />
                        }
                        {reservation.pricing.partnerDiscountAmount > 0 &&
                            <Row
                                left="Réduction"
                                right={"-" + reservation.pricing.partnerDiscountAmount + "€"} />
                        }

                        <Row
                            left="Frais de réservation"
                            right={reservation.pricing.reservationFees + "€"} />
                        <Box mt={2}>
                            <Row
                                left="TOTAL"
                                right={reservation.pricing.finalPrice + "€"}
                                bold />
                        </Box>
                        {/* :
                        <Box mt={2}>
                            <Row 
                                left="TOTAL"
                                right={totalAmount! - Number(coveragePrice) + "€"}
                                bold /> 
                        </Box>
                        } */}
                    </Box>
                    }
                    {error && <div>Error message</div>}
                </CardContent>
            </KlikCard>
        </Container>
    );
}

export default Receipt