import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import KlikCard from '../molecules/KlikCard';
import KlikCardHeaderVehicule from '../organisms/KlikCardHeaderVehicule';

import { Reservation } from '../../models/Reservation';
import { Partner } from '../../models/Partner';
import { Vehicule } from '../../models/Vehicule';

import { Grid, Typography, Box, Button, CardContent, makeStyles, Theme } from '@material-ui/core';
import { now } from '../../services/date';
import { DateTime } from 'luxon';
import { createStyles } from '@material-ui/styles';

type ReservationCardProps = {
	reservation: Reservation,
	partner: Partner | undefined,
	vehicule: Vehicule
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
			button: {
				marginTop: theme.spacing(1),
				marginInline: theme.spacing(0.5)
			}
		})
)

const ReservationCard = ({ reservation, partner, vehicule }: ReservationCardProps) => {
	const classes = useStyles();

	const [displayedDate, setDisplayedDate] = useState("");
	const [displayedHours, setDisplayedHours] = useState("");
	const [reservationDuration, setReservationDuration] = useState(0);

	useEffect(() => {
		const startDateTime = new Date(reservation.startDate);
		const endDateTime = new Date(reservation.endDate);

		// Calcul de la durée de réservation en heures
		setReservationDuration(Math.round((endDateTime.getTime() - startDateTime.getTime()) / 36e5));
		// Date(s) de la réservation
		if (startDateTime.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' }) === endDateTime.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' })) {
			setDisplayedDate(startDateTime.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' }));
		} else {
			setDisplayedDate(startDateTime.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' }) + ' - ' + endDateTime.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' }));
		}
		// Horaires de la réservation
		setDisplayedHours(startDateTime.toLocaleTimeString('fr-FR', { hour: 'numeric' }) + ' - ' + endDateTime.toLocaleTimeString('fr-FR', { hour: 'numeric' }))

	}, [reservation.startDate, reservation.endDate]);

	return (
		<KlikCard>
			<KlikCardHeaderVehicule
				partnerLabel={partner!.label}
				partnerAddress={partner!.address}
				vehiculeLabel={vehicule.label}
				typeLabel={vehicule.fulltype!.label}
				typeUrl={vehicule.fulltype!.url!} />
			<CardContent>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Typography align="center">{displayedDate}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography align="center">{displayedHours}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography align="center">Durée : {reservationDuration}h</Typography>
					</Grid>
					<Grid item xs={12}>
						<Box textAlign="center">
						<Grid item xs={12}>
							{ DateTime.fromJSDate(reservation.endDate) > now() && !reservation.hasBeenReturned &&
								<Button className={classes.button} component={Link} variant="contained" color="primary" to={{
									pathname: "/unlock/" + reservation.id + "/confirmation",
									state: {
										reservation: reservation
									}
								}}>Débloquer la remorque</Button>}
						</Grid>
						<Grid item xs={12}>
							{ !reservation.hasBeenReturned && reservation.hasBeenUnlocked &&
								<Button className={classes.button}
									component={Link}
									variant="contained"
									color="primary"
									to={"/return/" + reservation.id}
									disabled={reservation.startDate.getTime() > Date.now() || !reservation.hasBeenUnlocked }>Restituer la remorque</Button> }
						</Grid>
						</Box>
						<Grid item xs={12}>						
							<Box textAlign="center">
							{ DateTime.fromJSDate(reservation.startDate) > now() && !reservation.hasBeenUnlocked && !reservation.hasBeenReturned &&
								<Button className={classes.button}
									component={Link} variant="contained" color="primary" to={{
										pathname: "/cancel/" + reservation.id,
										state: {
											reservation: reservation
										}
									}}>Annuler la réservation</Button>}

							</Box>
							<Box textAlign="center">
								<Button className={classes.button}
								component={Link} variant="contained" color="primary" to={{
									pathname: "/receipt/" + reservation.id,
									state: {
										reservation: reservation
									}
								}}>Consulter la facture</Button>
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</KlikCard>
	);
}

export default ReservationCard;

