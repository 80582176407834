import React from 'react'

import { Container, Typography, Grid, Button } from '@material-ui/core'

import { useHistory } from 'react-router-dom'

import KlikHeader from '../../components/organisms/KlikHeader'

/**
 * Composant terminant l'annulation
 **/
function CancelError() {

    const history = useHistory()

    const next = () => {
        history.push(`/reservations`)
    }

    return (
      <Container>
      <KlikHeader title="Impossible d'annuler la réservation"/>
      <Grid item xs={12}>
        <Typography variant="subtitle1" align="center">{"Une erreur est survenue pendant l'annulation. Veuillez contacter le support KlikAndUse"}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={next}>Retour à mes réservations</Button>
      </Grid>
    </Container>
    )
}

export default CancelError