import { CircularProgress } from '@material-ui/core'
import React from 'react'
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import CancelContext from '../../contexts/CancelContext'
import { useReservation } from '../../services/firebase'
import CancelConfirmation from './CancelConfirmation'
import CancelDone from './CancelDone'
import CancelError from './CancelError'
import CancelWaiting from './CancelWaiting'

const Cancel = () => {
  const {path, url} = useRouteMatch()

  const params: any = useParams()
  const {loading, reservation} = useReservation({
    id: params.id
  })
  const ctx = {reservation: reservation!}

  return (
    <CancelContext.Provider value={ctx}>
    {loading && <CircularProgress/>}
    {reservation !== undefined &&
      <Switch>
        <Route exact path={`${path}/`}>
            <Redirect to={`${url}/confirmation`}/>
        </Route>
        <Route path={`${path}/confirmation`} component={CancelConfirmation}/>
        <Route path={`${path}/waiting`} component={CancelWaiting}/>
        <Route path={`${path}/error`} component={CancelError}/>

      </Switch>
       }
    {reservation === undefined &&
      <Switch>
        <Route path={`${path}/done`} component={CancelDone}/>

      </Switch>
       }
    </CancelContext.Provider>
  )
}

export default Cancel
