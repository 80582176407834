import React, { useContext } from 'react';

import { Button, Container, Typography, Grid, Box } from '@material-ui/core';

import { useHistory } from 'react-router-dom';

import UnlockContext from '../../contexts/UnlockContext';

import KlikHeader from '../../components/organisms/KlikHeader';

import { DateTime } from "luxon";

import { makeStyles } from '@material-ui/core/styles';
import KlikCode from '../../components/molecules/KlikCode';

const useStyles = makeStyles(theme => ({
    root: {
        width: '250px',
        marginInline: 'auto',
    },
    label: {
        fontSize: '23px'
    },
    bigLabel: {
        fontSize: '50px',
    }
}));

/**
 * Composant demandant la confirmation de déblocage ou incitant à patienter
 */
function UnlockConfirmation() {

    const { reservation } = useContext(UnlockContext)!;

    const classes = useStyles();
    const history = useHistory();
    const start = DateTime.fromJSDate(reservation!.startDate).setLocale('fr');
    const lockCode = reservation.code ?? ''

    const next = () => {
        history.push(`/unlock/${reservation!.id}/condition`);
    }

    const back = () => {
        history.push(`/reservations`);
    }

    return (
        <Container>
            <KlikHeader title="Votre remorque"/>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12}>
                    <Typography 
                        variant="subtitle1" 
                        align="center">{ reservation.startDate.getTime() < Date.now() ? 
                            "Votre réservation est désormais disponible" : "Votre réseration sera disponible le" }
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography 
                        align="center"
                        className={classes.label}>{start.toFormat('dd/LL/yyyy')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography 
                        align="center"
                        className={classes.label}>à</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography 
                        align="center"
                        className={classes.bigLabel}>{start.toFormat('HH')}h{start.toFormat('mm')}</Typography>
                </Grid>
                {!reservation.hasBeenUnlocked 
                ?
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={!(reservation.startDate.getTime() < Date.now())}
                        onClick={next}>Débloquer la remorque</Button>
                </Grid>
                :
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Typography align='center' variant="h1">Voici le code de votre cadenas</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <KlikCode value={lockCode}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mx='auto' width="max-content">
                            <Button variant="contained" color="primary" onClick={back}>Retour à mes réservations</Button>
                        </Box>
                    </Grid>
                </Grid>
                }
            </Grid>   
        </Container>
    );
};

export default UnlockConfirmation;