import React from 'react'

import {Reservation} from '../models/Reservation'

const ReturnContext = React.createContext<ReturnContextType | undefined>(undefined)

type ReturnContextType = {
    reservation: Reservation
};

export default ReturnContext