import React, { useState } from 'react'

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { storage } from '../../services/firebase'


const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		color: theme.palette.common.white,
		justifyContent: 'center'
	},
	boxes: {
		backgroundColor: theme.palette.primary.main,
		fontSize: '28px',
		padding: '7px',
		borderRadius: '14px',
		margin: '3.5px'
	},
}));


const KlikCode = ({value} : KlikCodeProps) => {

	const classes = useStyles();

	const lockImgPath = "referential/img/vehicule-check/cadenas-blanc.png";

	// Url firebase de l'image associée à l'étape
	const [imageUrl, setImageUrl] = useState("");

	// Récupération de l'image associée à l'étape de vérification
	storage.ref().child(lockImgPath).getDownloadURL().then((url: any) => {
			setImageUrl(url);
	})
	return (
		<Box className={classes.root}>
			{ value
				.split('')
				.map((number, index) => (<Box key={index} className={classes.boxes}>{number}</Box>)) }
				<Box className={classes.boxes}>
				<img src={imageUrl} alt="Illustration du cadenas à vérifier"/>
				</Box>
		</Box>
	);
};

type KlikCodeProps = {
	value : string
}

export default KlikCode;