import { Partner } from "./Partner";
import { ResponsePrice } from "./ResponsePrice";
import { Vehicule } from "./Vehicule";

// Réservation d'un véhicule
export class Reservation {

    // Identifiant de réservation
    id: string;

    // Date de début
    startDate: Date;

    // Date de fin
    endDate: Date;

    // userId
    userId: string;

    // Identifiant du partenaire
    partnerId: string;

    // Identifiant du véhicule
    vehiculeId: string;

    // Véhicule
    vehicule?: Vehicule;

    // Parternaire
    partner?: Partner;

    code?: string;

    hasBeenUnlocked?: boolean;

    hasBeenReturned?: boolean;

    paymentIntentId?: string;

    pricing: ResponsePrice;

    updated? : number

    constructor(
        id: string, 
        startDate: Date, 
        endDate: Date,
        userId: string,
        partnerId: string, 
        vehiculeId: string,
        pricing: ResponsePrice,
        code?: string,
        hasBeenUnlocked?: boolean,
        hasBeenReturned?: boolean,
        paymentIntentId?: string,
        updated?: number)
    {
    this.id = id;
    this.startDate = startDate;
    this.endDate = endDate;
    this.userId = userId;
    this.partnerId = partnerId;
    this.vehiculeId = vehiculeId;
    this.code = code;
    this.hasBeenUnlocked = hasBeenUnlocked;
    this.hasBeenReturned = hasBeenReturned;
    this.paymentIntentId = paymentIntentId;
    this.pricing = pricing;
    this.updated = updated;
    }


}