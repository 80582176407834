import React from 'react'

import { useBooking } from '../../contexts/BookingContext'

import {useRouteMatch, Route, Switch, Redirect } from 'react-router-dom'

// Components
import {PrivateRoute} from '../../components/atoms/PrivateRoute'

import BookEnd from './BookEnd'
import BookInfo from './BookInfo'
import BookPayment from './BookPayment'
import BookResume from './BookResume'
import { Box, CircularProgress } from '@material-ui/core'

const Book = () => {

	const { path } = useRouteMatch()
	const { booking, loaded } = useBooking()!

	if(loaded && booking === undefined){
		return <Redirect to="/partners" />
	} 

	if(!loaded) return (
		<Box width='100%' height="150px" display='flex' justifyContent='center' alignItems='center'>
						<CircularProgress color="secondary"/>
		</Box>
)

	return (
		<Switch>
            <Route path={`${path}/resume`} component={BookResume} />
            <PrivateRoute path={`${path}/payment`} component={BookPayment} />
            <PrivateRoute path={`${path}/info`} component={BookInfo} />
            <PrivateRoute path={`${path}/end`} component={BookEnd} />
        </Switch>
	)
}

export default Book