import {DateTime} from 'luxon'

export const now = (): DateTime => {
    return DateTime.fromJSDate(new Date()).set({minute: 0, second: 0, millisecond: 0})
}

export const today = (): DateTime => {
    return DateTime.fromJSDate(new Date()).set({hour: 0, minute: 0, second: 0, millisecond: 0})
}

export const isToday = (date: DateTime) => {
    return isSameDay(today(), date)
}

export const isAfterToday = (date: DateTime) => {
    const t = today()
    return date.year === t.year && date.ordinal > t.ordinal
}

export const isSameDay = (date1: DateTime | undefined, date2: DateTime | undefined): boolean => {
    if (!date1 || !date2) return false
    return isBetweenEqualsDay(date1, date2, date2)
}

export const isAfterDay = (date1: DateTime | undefined, date2: DateTime | undefined): boolean => {
    if (date1 === undefined || date2 === undefined) return false
    return date1.year === date2.year && date1.ordinal > date2.ordinal
}

export const isBeforeDay = (date1: DateTime | undefined, date2: DateTime | undefined): boolean => {
    if (date1 === undefined || date2 === undefined) return false
    return date1.year === date2.year && date1.ordinal < date2.ordinal
}

export const isBetweenEqualsDay = (
    current: DateTime | undefined, from: DateTime | undefined, to: DateTime | undefined) => {
    if (!current || !from || !to) return false
    return current >= transformDay(from!, 'start') && current <= transformDay(to, 'end')
}

export const isBetweenDay = (is: DateTime | undefined, from: DateTime | undefined, to: DateTime | undefined) => {
    if (is === undefined || from === undefined || to === undefined) return false
    return is! > transformDay(from!, 'start') && is! < transformDay(to, 'end')
}

export const transformDay = (date: DateTime, type: 'start' | 'end'): DateTime => {
    let result = date
    if (type === 'end') {
        result = result.set({hour: 23})
    } else {
        result = result.set({hour: 0})
    }

    return result
}
export const endOfDay = (date: DateTime): DateTime => {
    return date.set({hour: 23})
 
}
export const startOfDay = (date: DateTime): DateTime => {
    return date.set({hour: 0})

}