import React from 'react'
import { Box, Button, CardContent, Container, Link, makeStyles } from '@material-ui/core'

import { logout, useAuth } from '../../contexts/UserContext'
import KlikHeader from '../../components/organisms/KlikHeader'
import KlikCard from '../../components/molecules/KlikCard'

const useStyles = makeStyles({
    button: {
        display: 'flex',
        margin: '1em',
        alignSelf: 'center'
    }
});

function AuthProfile() {
    const { profile } = useAuth()
    const classes = useStyles();

    return (
        <Container>
            <KlikHeader title="Mon compte" />
            <Box display="flex" flexDirection="column" justifyContent="center">
            <Box m="1em">
                <KlikCard>
                    <CardContent>

                        {profile != null ?
                            <ul>
                                <li>Nom : {profile.firstName}</li>
                                <li>Prénom : {profile.lastName} </li>
                                <li>E-mail : {profile.email}</li>
                                <li>N° de permis : {profile.drivingLicenseNumber}</li>
                            </ul>
                            : <p>En attente du serveur</p>
                        }
                        <Link href="/auth/profile/edit">Modifier les informations</Link>
                    </CardContent>
                </KlikCard>
            </Box>
            
            <Link className={classes.button} href="/reservations">Retour à mes réservations</Link>
            <Button className={classes.button} variant="outlined" color="primary" onClick={() => logout()}>Déconnexion</Button>
            </Box>
        </Container>
    )
}

export default AuthProfile