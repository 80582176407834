import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  spacing: 7,
  typography: {
    body1: {
      fontSize: '14px',
    },
    body2: {
      fontSize: '12px',
    },
    h1: {
      fontSize: '23px',
      fontWeight: 500,
    },
    h2: {
      fontSize: '23px',
      fontWeight: 500,
    },
    h3: {
      fontSize: '18px',
      fontWeight: 500,
    }
  },
  palette: {
    primary: {
      main: '#F7A512'
    }, 
    text: {
      primary: '#606060'
    }
  },
  props: {
    MuiContainer: {
      maxWidth: 'sm'
    },
    MuiButton: {
      disableElevation: true
    }
  },
});

theme.overrides = {
  MuiTypography: {
    root: {
      color: theme.palette.text.primary
    },
  },
  MuiContainer: {
    root: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },
  MuiButton: {
    root: {
    	maxHeight: '50px',
    	fontWeight: 700,
      borderRadius: '25px',
      textTransform: 'none',
      padding: '15px 42px 14px 42px',
      lineHeight: 1.17214286
    },
    contained: {
    	backgroundColor: '#fff',
    	color: '#606060',
      fontWeight: 'normal'
    },
    containedPrimary: {
      color: '#fff',
      fontWeight: 700
    },
    outlined: {
      padding: '13px 40px 12px 40px',
    	color: '#fff',
    	border: '2px solid #fff'
    },
    outlinedPrimary: {
      border: '2px solid ' + theme.palette.primary.main,
      fontWeight: 'normal',
      color: theme.palette.text.primary
    }
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: '25px',
      
    },
    notchedOutline: {
      borderColor : theme.palette.primary.main
    }
  },
  MuiCardContent: {
    root: {
      padding: theme.spacing(3),
      '&:last-child' : {
        paddingBottom: theme.spacing(3)
      }
    }
  }
};


/* 

'& $notchedOutline': {
          borderColor,
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: theme.palette.text.primary,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            borderColor,
          },
        },
        '&$focused $notchedOutline': {
          borderColor: theme.palette.primary.main,
          borderWidth: 2,
        },
        '&$error $notchedOutline': {
          borderColor: theme.palette.error.main,
        },
        '&$disabled $notchedOutline': {
          borderColor: theme.palette.action.disabled,
        },
      },
      notchedOutline: {
        border: '2px solid #fff'
      }


*/

export default theme;