import React, {useState} from 'react'
import {Button, Grid, TextField, Typography} from '@material-ui/core'
import {Profile} from '../../../models/Profile'

type BookingUserProfileProps = {
    onSubmit: (data: {}) => void
    profile: Profile | null
}

/**
 * Composant affichant le processus de selection d'un créneau horaire
 */
const BookingUserProfile = ({onSubmit, profile}: BookingUserProfileProps) => {
    const [firstName, setFirstName] = useState(profile?.firstName ?? '')
    const [lastName, setLastName] = useState(profile?.lastName ?? '')
    const [address, setAddress] = useState(profile?.address ?? '')
    const [email, setEmail] = useState(profile?.email ?? '')
    const [birthDate, setBirthDate] = useState(profile?.birthDate ?? new Date())
    const [city, setCity] = useState(profile?.city ?? '')
    const [zipCode, setZipCode] = useState(profile?.zipCode ?? '')

    const handleFirstNameChange = (event: any) => {
        setFirstName(event.target.value)
    }

    const handleLastNameChange = (event: any) => {
        setLastName(event.target.value)
    }

    const handleAddressChange = (event: any) => {
        setAddress(event.target.value)
    }

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value)
    }

    const handleCityChange = (event: any) => {
        setCity(event.target.value)
    }

    const handleZipCodeChange = (event: any) => {
        setZipCode(event.target.value)
    }

    const handleBirthDateChange = (event: any) => {
        setBirthDate(event.target.value)
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        onSubmit({
            firstName,
            lastName,
            address,
            email,
            birthDate,
            city,
            zipCode
        })
        e.preventDefault()
    }

    return (
        <Grid component="form" container spacing={2} noValidate onSubmit={handleSubmit}>
            <Grid item xs={12}>
                <Typography align="center">Veuillez saisir quelques informations personnelles.</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={firstName}
                    onChange={handleFirstNameChange}
                    label="Prénom"/>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={lastName}
                    onChange={handleLastNameChange}
                    label="Nom de famille"/>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="date"
                    value={birthDate}
                    onChange={handleBirthDateChange}
                    label="Date de naissance"/>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={address}
                    onChange={handleAddressChange}
                    label="Adresse"/>
            </Grid>
            <Grid item xs={7}>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={city}
                    onChange={handleCityChange}
                    label="Ville"/>
            </Grid>
            <Grid item xs={5}>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={zipCode}
                    onChange={handleZipCodeChange}
                    label="Code postal"/>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    label="Email"/>
            </Grid>
            <Grid item xs={12}>
                <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    color="primary">Valider</Button>
            </Grid>
        </Grid>
    )
}

export default BookingUserProfile