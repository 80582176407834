import React, {useEffect, useRef, useState} from 'react'
import * as firebase from 'firebase'
import {Box, Container, Typography} from '@material-ui/core'

import ConfirmationCode from '../../components/layouts/Login/ConfirmationCode'
import PhoneNumber from '../../components/layouts/Login/PhoneNumber'

import {ReactComponent as Logo} from '../../components/svg/Logo.svg'
import {auth} from '../../services/firebase'

const signInButtonId = 'sign-in-button'
const createAppVerifier = (container: any) =>
    new firebase.auth.RecaptchaVerifier(
        container, {
            'size'    : 'invisible',
            'callback': () => {
            }
        })

// Workaround typing of material ui... Fixed in material UI 5
declare module '@material-ui/core/Box' {
    interface BoxProps {
        ref?: React.MutableRefObject<HTMLElement | null>;
    }
}

/**
 * Composant permettant l'authentification par numéro de téléphone
 */
function AuthLogin() {

    const [phoneNumberIsLoading, setPhoneNumberIsLoading] = useState<boolean>(false);
    const [codeIsLoading, setCodeIsLoading] = useState<boolean>(false);

    const verifierRef = useRef<HTMLElement | null>(null)
    const [appVerifier, setAppVerifier] = useState<firebase.auth.RecaptchaVerifier | null>(null)

    const [confirmationResult, setConfirmationResult] = useState<firebase.auth.ConfirmationResult | null>(null)
    const doesntHaveAValidPhoneNumber = confirmationResult == null

    useEffect(() => {
        setAppVerifier(createAppVerifier(verifierRef.current))
    }, [])

    /**
     * Envoi d'un SMS une fois le numéro de téléphone saisi
     */
    const sendSMS = (value: string) => {
        if (!appVerifier) return
        setPhoneNumberIsLoading(true)
        auth.signInWithPhoneNumber(value, appVerifier)
            .then(function (result) {
                setConfirmationResult(result)
                setPhoneNumberIsLoading(false)
            })
            .catch(function (error) {
                console.error('Erreur lors de l\'envoi du SMS', error)
                setPhoneNumberIsLoading(false)
                // Needed in case of error so the user can retry :
                appVerifier.render()
                    .then((widgetId) => {
                        return (window as any).grecaptcha?.reset(widgetId)
                    })
            })
    }

    /**
     * Authentification via le code envoyé par SMS
     */
    const signInWithPhoneNumber = (value: string) => {
        setCodeIsLoading(true)
        confirmationResult?.confirm(value)
            .catch(function (error) {
                setCodeIsLoading(false)
                console.error('Erreur lors de la vérification du code', error)
            })
    }

    return (
        <Container>
            <Box height="90vh" display="flex" flexDirection="column" justifyContent="space-evenly">
                <Box maxWidth="200px" mx="auto">
                    <Logo/>
                </Box>
                <Typography variant="h2" align="center">Connexion</Typography>
                {doesntHaveAValidPhoneNumber
                    ? <PhoneNumber onSubmit={sendSMS} loading={phoneNumberIsLoading}/>
                    : <ConfirmationCode onSubmit={signInWithPhoneNumber} loading={codeIsLoading}/>
                }
            </Box>
            <Box id={signInButtonId} ref={verifierRef}/>
        </Container>
    )
}

export default AuthLogin
