import React from 'react'

import ReturnContext from '../../contexts/ReturnContext'

import {Route, Switch, useParams, useRouteMatch} from 'react-router-dom'

import {CircularProgress} from '@material-ui/core'

import ReturnEnd from './ReturnEnd'
import ReturnInventory from './ReturnInventory'
import ReturnPadlock from './ReturnPadlock'
import ReturnPicture from './ReturnPicture'
import ReturnSatisfaction from './ReturnSatisfaction'
import ReturnCode from './ReturnCode'

import {useReservation} from '../../services/firebase'
import {Reservation} from '../../models/Reservation'

const Return = () => {
    const {path} = useRouteMatch()
    const params: any = useParams()

    const {error, loading, reservation} = useReservation({
        id: params.id
    })

    const ctx = {reservation: reservation as Reservation}

    return (
        <ReturnContext.Provider value={ctx}>
            {loading && <CircularProgress/>}
            {reservation !== null &&
            <Switch>
                <Route path={`${path}/inventory`} component={ReturnInventory}/>
                <Route path={`${path}/picture`} component={ReturnPicture}/>
                <Route path={`${path}/padlock`} component={ReturnPadlock}/>
                <Route path={`${path}/satisfaction`} component={ReturnSatisfaction}/>
                <Route path={`${path}/end`} component={ReturnEnd}/>
                <Route path={`${path}`} component={ReturnCode}/>
            </Switch>}
            {reservation == null && <div>Not found reservation</div>}
            {error && <div>Error message</div>}
        </ReturnContext.Provider>
    )
}

export default Return