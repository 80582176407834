import React, {useEffect, useState} from 'react'
import {Box, Chip, CircularProgress, Container} from '@material-ui/core'
import {useHistory} from 'react-router-dom'
import * as firebase from 'firebase'

import {useAuth} from '../../contexts/UserContext'

import BookingUserProfile from '../../components/layouts/ProfileInfo/BookingUserProfile'
import BookingLegalInformations from '../../components/layouts/ProfileInfo/BookingLegalInformations'

import KlikHeader from '../../components/organisms/KlikHeader'

import {useBooking} from '../../contexts/BookingContext'
import {db, functions} from '../../services/firebase'

enum steps {
    UserProfile,
    LegalInformations
}

function BookInfo() {
    const history = useHistory()

    // const [paymentId, setPaymentId] = useState('')
    // const [assurance, setAssurance] = useState(false)
    // const [freeHoursActivated, setFreeHoursActivated] = useState(false)
    // const [pricing, setPricing] = useState<any>(null)
    // const [amount, setAmount] = useState()

    // useEffect(() => {
	// 	setFreeHoursActivated(JSON.parse(localStorage.getItem("freeHoursActivated")!))
	// 	setAssurance(JSON.parse(localStorage.getItem("assurance")!))
    //     setPricing(JSON.parse(localStorage.getItem("pricing")!))
    //     setPaymentId(JSON.parse(localStorage.getItem("paymentId")!))
    //     setAmount(JSON.parse(localStorage.getItem("amount")!))
	// }, [])

    const {user, profile} = useAuth()
    const {booking, loaded} = useBooking()!
    const [step, setStep] = useState(steps.UserProfile)
    const [globalData, setGlobalData] = useState<any>(profile)

    const updateUserProfile = (data: {}) => {
        setGlobalData(data)
        setStep(steps.LegalInformations)
    }

    // Get pricing info
    
    const [pricingGenerator, setPricingGenerator] = useState<any>({
        startDate: null,
        endDate: null,
        partnerId: null,
        withInsurance: null,
        discountKey: null,
        discountValue: null,
    })
    useEffect(() => {
        let pricingGenerator = localStorage.getItem("pricingGenerator")
        if (pricingGenerator !== null) {
            pricingGenerator = JSON.parse(pricingGenerator)
            setPricingGenerator(pricingGenerator)
        }
    }, [])

    // Get payment intent
    let paymentIntentId = localStorage.getItem("paymentIntentId")

    const [validationIsLoading, setValidationIsLoading] = useState<boolean>(false);

    const updateLegalInformations = async (data: any) => {
        setValidationIsLoading(true)
        setGlobalData(Object.assign(globalData, data))

        const vehicleRef = db.collection('vehicles').doc(booking?.vehicule.id)

        const userRef = db.collection('users').doc(user!.uid)
        // TODO : Warning on conflicts with others update. (eg: reservations)
        await userRef.set(globalData)

        const newReservation = db.collection('reservations').doc()
        
        const createReservation = functions.httpsCallable('createReservation')
        // Modify generic pricing generator to fit custom key/Value
        pricingGenerator[pricingGenerator.discountKey] = pricingGenerator.discountCode
        createReservation({
            pricing: pricingGenerator,
            paymentIntentId: paymentIntentId,
            vehiculeId : booking!.vehicule.id,
            userInfo: {
                firstName: globalData.firstName, 
                lastName: globalData.lastName, 
                address : globalData.address, 
                city : globalData.city, 
                zipCode : globalData.zipCode, 
                email: globalData.email,
                drivingLicenseNumber : globalData.drivingLicenseNumber,
                drivingLicenseCity : globalData.drivingLicenseCity,
                drivingLicensePictureId : globalData.drivingLicensePictureId,
            },
        })

        // TODO : Add security rules that validates availability or move this code into a cloud function.
        await db.runTransaction(async (transaction) => {
            
            // Add references to other models
            transaction.update(userRef, {
                reservations: firebase.firestore.FieldValue.arrayUnion(newReservation.id)
            })
            transaction.update(vehicleRef, {
                reservations: firebase.firestore.FieldValue.arrayUnion(newReservation.id)
            })
        })
 
        history.push('/book/end')
    }


    const render = () => {
        switch (step) {
            case steps.UserProfile:
                return <BookingUserProfile onSubmit={updateUserProfile} profile={profile}/>
            case steps.LegalInformations:
                return <BookingLegalInformations onSubmit={updateLegalInformations} loading={validationIsLoading} profile={profile}/>
            default:
                console.warn('Unhandled case on booking steps.')
                return null
        }
    }
    if (loaded && booking === undefined) {
        history.push('/book');
    }

    if (!loaded) return (
    <Box width='100%' height="150px" display='flex' justifyContent='center' alignItems='center'>
            <CircularProgress color="secondary"/>
    </Box>)

    return (
        <Container>
            <KlikHeader title="Vos informations"/>
            <Box mb={3} display="flex" justifyContent="center">
                <Chip label={'Étape ' + Number(step+1) + '/2'}/>
            </Box>
            {render()}
        </Container>
    )

}

export default BookInfo