import React from 'react';
import 'firebase/auth';
import { Link } from 'react-router-dom';
import { Button, Grid, Typography, Box, Container } from '@material-ui/core';

import { ReactComponent as CheckIcon } from '../../assets/img/CheckIcon.svg';

import { makeStyles } from '@material-ui/core/styles';

import KlikHeader from '../../components/organisms/KlikHeader'

// A style sheet
const useStyles = makeStyles({
    subtitle: {
        lineHeight: '26.95px',
        maxWidth: '300px',
        textAlign: 'center',
        marginInline: 'auto',
        color: '#606060',
        fontSize: '23px',
        marginTop: '18px'
    }
});

/**
 * Page de confirmation de réservation
 */
const BookEnd = () => {

    const classes = useStyles();

    return (
        <Container>
            <Box display="flex" flexDirection="column" justifyContent="space-between" height="80vh">
                <KlikHeader />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box maxWidth="106px" mx="auto">
                            <CheckIcon />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" className={classes.subtitle}>Votre remorque est maintenant réservée !</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Button component={Link} to="/reservations" fullWidth variant="contained" color="primary">Voir mes réservations</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button component={Link} to="/">Revenir à l'accueil</Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export default BookEnd;