import React, { useContext, useState } from 'react';

import { Box, Button, CircularProgress, Container, Grid, Typography } from '@material-ui/core';

import { useHistory } from 'react-router-dom';

import KlikHeader from '../../components/organisms/KlikHeader';

import { DateTime } from "luxon";

import { makeStyles } from '@material-ui/core/styles';
import CancelContext from '../../contexts/CancelContext';
import { functions, usePricing } from '../../services/firebase';


const useStyles = makeStyles(theme => ({
  root: {
    width: '250px',
    marginInline: 'auto',
  },
  label: {
    fontSize: '23px'
  },
  bigLabel: {
    fontSize: '50px',
  }
}));

/**
 * Composant demandant la confirmation d'annulation
 */
function CancelConfirmation() {

  const { reservation } = useContext(CancelContext)!

  const partnerId = reservation.partnerId
  const start = DateTime.fromJSDate(reservation.startDate).setLocale('fr')
  const end = DateTime.fromJSDate(reservation.endDate).setLocale('fr')

  const classes = useStyles()
  const history = useHistory()

  const { error, loading } = usePricing({ 'startDate': start.toISO(), 'endDate': end.toISO(), 'partnerId': partnerId, 'withInsurance': false, 'discountKey': "", 'discountCode': "" })

  const deleteReservation = functions.httpsCallable('deleteReservation')
  const [loadingRefund, setLoadingRefund] = useState<boolean>(false)

  const cancel = async () => {
    setLoadingRefund(true)
    deleteReservation(reservation.id).then((result) => {
      setLoadingRefund(false)
      if (result.data.status === "done"){
        history.push(`/cancel/${reservation!.id}/done`)

      }
      else {
        history.push(`/cancel/${reservation!.id}/error`)

      }


    })


  }

  return (
    <Container>
      <KlikHeader title="Annulation de la réservation" />
      <Grid item xs={12}>
        <Typography variant="subtitle1" align="center">{"Vous vous apprêtez à annuler la réservation suivante :"}</Typography>
      </Grid>
      {start.toLocaleString(DateTime.DATE_FULL) === end.toLocaleString(DateTime.DATE_FULL)
        ?
        <div>
          <Grid item xs={12}>
            <Typography
              align="center" className={classes.label}>Le {start.toFormat('dd/LL/yyyy')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              align="center"
              className={classes.label}>de {start.toFormat('HH')}h{start.toFormat('mm')} à {end.toFormat('HH')}h{end.toFormat('mm')}</Typography>
          </Grid>
        </div>
        :
        <div>
          <Grid item xs={12}>
            <Typography
              align="center" className={classes.label}>Du {start.toFormat('dd/LL/yyyy')} à {start.toFormat('HH')}h{start.toFormat('mm')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              align="center" className={classes.label}> au {end.toFormat('dd/LL/yyyy')} à {end.toFormat('HH')}h{start.toFormat('mm')}</Typography>
          </Grid>
        </div>}
      {loading ?
        <Box width='100%' height="150px" display='flex' justifyContent='center' alignItems='center'>
          <CircularProgress />
        </Box>
        :
        <div>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align="center">{"En annulant la réservation, vous serez remboursé des frais de location. Seuls les frais de réservation ne sont pas remboursés."}</Typography>
          </Grid>
          {loadingRefund
            ?
            <Box width='100%' height="150px" display='flex' justifyContent='center' alignItems='center'>
              <CircularProgress color="secondary" />
            </Box>
            :
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={cancel}>Annuler la reservation</Button>
            </Grid>
          }
        </div>
      }
      {error && <div>Error message</div>}
    </Container>
  )
}

export default CancelConfirmation