import React, {useEffect, useState} from 'react'

import {Box, IconButton, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {ReactComponent as CameraIcon} from '../svg/CameraIcon.svg'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

const useStyles = makeStyles(theme => ({
    root   : {
        width       : '200px',
        height      : '160px',
        overflow    : 'hidden',
        borderRadius: '25px',
        position    : 'relative',
        boxSizing   : 'border-box',
        boxShadow   : '0px 4px 4px rgba(0, 0, 0, 0.1)',
        marginLeft  : 'auto',
        marginRight : 'auto',
    },
    button : {
        backgroundColor: theme.palette.primary.main,
        width          : '100%',
        height         : '100%',
        display        : 'flex',
        flexDirection  : 'column',
        alignItems     : 'center',
        justifyContent : 'center',
        cursor         : 'pointer',
        transition     : 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&:hover'      : {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    icon   : {
        marginBottom: '25px',
    },
    label  : {
        color       : '#fff',
        fontWeight  : 'bold',
        textAlign   : 'center',
        paddingLeft : '14px',
        paddingRight: '14px',
        textSize    : '12px',
    },
    input  : {
        visibility                     : 'hidden',
        width                          : '0px',
        height                         : '0px',
        '&::-webkit-file-upload-button': {
            visibility: 'hidden'
        }
    },
    type   : {
        position       : 'absolute',
        top            : '14px',
        left           : '14px',
        zIndex         : 100,
        padding        : '3.5px 7px',
        borderRadius   : '14px',
        color          : 'white',
        fontSize       : '12px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        fontWeight     : 'bold',
        textTransform  : 'uppercase',
    },
    action : {
        position: 'absolute',
        zIndex  : 100,
        top     : '2px',
        right   : '2px'
    },
    image  : {
        '& img': {
            position       : 'absolute',
            maxWidth       : 'none',
            maxHeight      : '100%',
            backgroundColor: '#f5F5F5',
            left           : '50%',
            top            : '50%',
            transform      : 'translateX(-50%) translateY(-50%)'
        }
    },
    caption: {
        position       : 'absolute',
        bottom         : '0',
        zIndex         : 100,
        fontSize       : '14px',
        color          : 'white',
        left           : '0',
        right          : '0',
        padding        : '14px',
        backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%)',
        fontWeight     : 'bold',
        whiteSpace     : 'nowrap',
        overflow       : 'hidden',
        textOverflow   : 'ellipsis',
    }
}))

const useDeleteButtonStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.error.main
    },
}))

const KlikFileInput = ({label = 'Joindre une photo', defaultValue, onChange}: KlikFileInputProps) => {
    const classes = useStyles()
    const deleteButtonClasses = useDeleteButtonStyles()

    const [selectedFile, setSelectedFile] = useState<File | undefined>()
    const [preview, setPreview] = useState<any>(defaultValue)

    const [type, setType] = useState<string | undefined>()
    const [caption, setCaption] = useState<string | undefined>()

    useEffect(() => {
        if (!selectedFile) {
            return
        }

        setType(selectedFile!.type.slice(6))
        setCaption(selectedFile!.name.split('.')[0])
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)

    }, [selectedFile])

    const onFileChange = (e: any) => {
        setSelectedFile(e.target.files[0])
        onChange(e.target.files[0])
    }

    const onDelete = () => {
        setSelectedFile(undefined)
        setPreview(undefined)
        onChange(undefined)
    }

	return (
		<Box className={classes.root}>
			{ preview === undefined && <label
				htmlFor="file" 
				className={classes.button}>
				<CameraIcon 
			  		className={classes.icon} />
			  	<Typography 
			  		className={classes.label}>
			  			{label}</Typography>
			  	<input 
			  		id="file" 
			  		type="file" 
			  		accept="image/*" 
			  		className={classes.input} 
			  		onChange={onFileChange}
			  		aria-label="" />
			</label> } 
			{preview !== undefined && <>
				<Box className={classes.type}>{ type }</Box>
				<Box className={classes.action}>
					<IconButton component="span" classes={deleteButtonClasses} onClick={onDelete}>
			        	<HighlightOffIcon />
			        </IconButton>
				</Box>
				<Box className={classes.image}>
					<img src={preview} className={classes.image} alt="Votre pièce jointe"/>
				</Box>
				<Box className={classes.caption} component="span">{ caption }</Box>
			</> }
		</Box>
		
	);
};

export default KlikFileInput

type KlikFileInputProps = {
    label?: string,
	defaultValue?: string,
    onChange: (file: any) => void
}