import React, {useContext, useState} from 'react'

import { Container, Button, TextareaAutosize, Box, Typography } from '@material-ui/core';

import KlikSmileyTerminal from '../../components/molecules/KlikSmileyTerminal'
import KlikHeader from '../../components/organisms/KlikHeader'

import { useHistory } from 'react-router-dom';

import ReturnContext from '../../contexts/ReturnContext';

import { makeStyles } from '@material-ui/core/styles';
import {db} from '../../services/firebase'

const useStyles = makeStyles(theme => ({
	label: {
		marginTop : theme.spacing(4),
		color: theme.palette.text.primary
	},
	textarea: {
		width: 'calc(100% - ' + theme.spacing(4) + 'px)',
		borderRadius: '15px',
		border: '1px solid ' + theme.palette.primary.main,
		padding: theme.spacing(2)
	}
}));

const ReturnSatisfaction = () => {

	const { reservation } = useContext(ReturnContext)!;
	const history = useHistory();
	const [feedbackText, setFeedbackText] = useState("")
	const [smiley, setSmiley] = useState<number | null>(null)
	const next = async () => {
		await db.collection("reservations").doc(reservation.id)
			.update({
				feedback: {
					text: feedbackText,
					smiley,
				},
				hasBeenUnlocked: true
			})
		history.push(`/return/${reservation.id}/end`)
	}

	const classes = useStyles();

	// const [smiley, setSmiley] = useState<number|null>();

	return (
		<Container>			
			<KlikHeader
				back={"/return/" + reservation.id + "/padlock"}
				title="Êtes-vous satisfait.e de votre experience ?" />
			<Box
				display='flex'
				flexDirection='column'
				justifyContent='center'
				alignItems='center'>
				<Typography align='center' className={classes.label}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ultrices lacus egestas in sit tristique dignissim sapien.</Typography>
				<Box my={8}>
					<KlikSmileyTerminal onChange={setSmiley} />
				</Box>
				<Box width="300px">
					<TextareaAutosize
						value={feedbackText}
						onInput={(e) => setFeedbackText(e.currentTarget.value)}
						className={classes.textarea}
						rowsMin={3}
						placeholder="Votre message ici" />
				</Box>
				<Box width="230px" mt={3}>
					<Button 
						variant="contained" 
						color="primary"
						fullWidth
						onClick={next}>Valider</Button>
				</Box>
			</Box>	
		</Container>
	);
};

export default ReturnSatisfaction;