import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Button, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as Logo } from '../assets/img/logo.svg';

const useStyles = makeStyles(theme => ({
  logoContainer : {
    height: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullHeight : {
    height: '100%'
  },
  mb3 : {
    marginBottom: theme.spacing(3)
  },
  textDesc: {
    marginBottom: '1rem',
    [theme.breakpoints.up('md')]: {

    marginBottom: '5rem',
    }
    // marginBottom: '-5rem'
  },
  actionButton: {
      maxWidth: '270px',
      marginTop: '.5rem'
    // }
    
  }
}));

/**
 * Page d'accueil
 */
const Home = () => {

    const classes = useStyles();

    return (
      <Box
        height="100vh">
          <Container 
            className={classes.logoContainer}>
              <Logo />
          </Container>

          <Box 
              component="div" 
              bgcolor="primary.main"
              height="50%">
                
              <Container
                className={classes.fullHeight}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.fullHeight}
                  // maxWidth="270px"
                  mx="auto">
                    <Typography align="center" variant="h1" className={classes.textDesc} >Emprunter une remorque partagée</Typography>
                    <Button 
                      variant="contained" 
                      component={Link} 
                      to="/partners"
                      fullWidth
                      className={classes.actionButton}>Louer une remorque</Button> 
                    <Button 
                      variant="outlined" 
                      component={Link}
                      className={classes.actionButton}
                      fullWidth
                      to="/reservations">Mes réservations</Button>
                </Box> 
              </Container>
          </Box>
      </Box>
    );
}

export default Home;
