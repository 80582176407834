import React, { useContext, useState } from 'react';
import { useEffect } from 'react';

import { Booking } from '../models/Booking';

type BookingContextType = {
	booking: Booking | undefined;
	loaded: boolean;
	setBooking: (value: Booking) => void;
};

export const BookingContext = React.createContext<BookingContextType | undefined>(undefined);

type BookingProviderProps = { children: React.ReactNode; };

export const BookingProvider = ({ children }: BookingProviderProps) => {

	const [booking, setBooking] = useState<Booking>();
	const [loaded, setLoaded] = useState<boolean>(false);

	useEffect(() => {
		if (booking !== undefined) localStorage.setItem("booking", JSON.stringify(booking))
	}, [booking])

	useEffect(() => {
		if (localStorage.getItem("booking") !== "undefined" && localStorage.getItem("booking")) {
			setBooking({
				start: new Date(JSON.parse(localStorage.getItem("booking")!).start),
				end: new Date(JSON.parse(localStorage.getItem("booking")!).end),
				partner: JSON.parse(localStorage.getItem("booking")!).partner,
				vehicule: JSON.parse(localStorage.getItem("booking")!).vehicule,
				oldReservation: JSON.parse(localStorage.getItem("booking")!).oldReservation
			})
		}
		setLoaded(true)
	}, [])

	return (
		<BookingContext.Provider value={{ booking, loaded, setBooking }}>
			{children}
		</BookingContext.Provider>
	);
};

export const useBooking = () => useContext(BookingContext);
