import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {Container, Grid} from '@material-ui/core'

import {Vehicule} from '../../models/Vehicule'
import {Partner} from '../../models/Partner'

import PartnerSingleCard from '../../components/layouts/PartnerSingleCard'

import KlikHeader from '../../components/organisms/KlikHeader'
import {VehiculeType} from '../../models/VehiculeType'
import {db, storage} from '../../services/firebase'

/**
 * Composant affichant la liste des véhicules d'un partenaire
 */
function PartnerList() {

    const [partner, setPartner] = useState<Partner>()
    const [vehicules, setVehicules] = useState<Array<Vehicule>>([])

    const {id} = useParams<{ id: string }>()

    useEffect(() => {
        (async () => {
            const partnerDoc = await db.collection('partners').doc(id).get()
            let partner: Partner = partnerDoc.data() as Partner
            partner.id = id
            setPartner(partner)
        })()
    }, [id])

    useEffect(() => {
        if (!id) return

        db.collection('vehicles')
            .where('partnerId', '==', id)
            .get()
            .then((snap) => {
                    const vehiclesAsync: Promise<Vehicule>[] = snap.docs
                        .map(doc => ({
                            id: doc.id,
                            ...doc.data(),
                        }) as Vehicule)
                        .map(async (rawVehicle) => {
                            const typeDoc = await db.collection('vehicleTypes').doc(rawVehicle.type).get()
                            const type = typeDoc.data() as VehiculeType | undefined
                            return {
                                ...rawVehicle,
                                fulltype: type ? {
                                    ...type,
                                    url: await storage.ref().child('referential/img/' + type.imageLabel + '.jpg')
                                        .getDownloadURL(),
                                } : undefined,
                            }
                        })
                    return Promise.all(vehiclesAsync)
                }
            )
            .then((vehicles) => {
                setVehicules(vehicles)
            })
    }, [id])

    return (
        <Container>
            <KlikHeader title="Réservation" back="/partners"/>
            {partner !== undefined &&
            <Grid container spacing={3}>
                {vehicules.map((vehicule) => (
                    <Grid key={vehicule.id} item xs={12}>
                        <PartnerSingleCard
                            vehicule={vehicule}
                            partner={partner}
                            isUpdate={false}
                            reservation={undefined}/>
                    </Grid>
                ))}
            </Grid>
            }
        </Container>
    )
}

export default PartnerList