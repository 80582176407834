import React from 'react'

import {Reservation} from '../models/Reservation'

const UnlockContext = React.createContext<UnlockContextType | undefined>(undefined)

type UnlockContextType = {
    reservation: Reservation
};

export default UnlockContext