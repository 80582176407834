import React, {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'

import {Button, Container, Grid, TextField, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {Autocomplete} from '@material-ui/lab'

import {ReactComponent as MapMarker} from '../../assets/img/map-marker.svg'

import {Partner} from '../../models/Partner'

import KlikHeader from '../../components/organisms/KlikHeader'
import {db} from '../../services/firebase'

// A style sheet
const useStyles = makeStyles({
    root: {
        textAlign: 'center',
        height: '80vh'
    },
    subtitle: {
        lineHeight: '26.95px',
        maxWidth: '300px',
        textAlign: 'center',
        marginInline: 'auto',
        color: '#606060',
        fontSize: '23px',
        marginTop: '18px'
    }
});


/**
 * Composant affichant le champ de recherche des partenaires
 */
const PartnerSearch = () => {
    const history = useHistory();

    const [partners, setPartners] = useState(new Array<Partner>());

      // Initialisation
    useEffect(() => {
        // Récupération des partenaires
        db.collection("partners").get().then((querySnapshot: any) => {

            let partnersList: Array<Partner> = [];

            // Récupération de la map des partenaires
            querySnapshot.forEach((doc: any) => {
                let partner: Partner = doc.data();
                partner.id = doc.id;
                partnersList.push(partner);
            });
            setPartners(partnersList);
        })
    }, []);

    const [selectedPartner, setSelectedPartner] = useState<Partner>();
  
    const handlePartnerChange = (event: any, newValue: any) => {
        setSelectedPartner(newValue);
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        if(selectedPartner !== undefined) {
            history.push("/partners/" + selectedPartner.id)
        }
        e.preventDefault();
    }

    const classes = useStyles();

    return (
        <Container>
            <KlikHeader />
            <Grid
                container
                direction="column"
                justify="space-around"
                alignItems="stretch"
                className={classes.root}>
                <Grid item>
                    <MapMarker/>
                    <Typography variant="subtitle1" className={classes.subtitle}>Veuillez saisir le nom du point de retrait</Typography> 
                </Grid>
                <Grid item>
                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="partner-search"
                                    options={partners}
                                    getOptionLabel={(partner) => partner.label}
                                    value={selectedPartner}
                                    onChange={handlePartnerChange}
                                    renderInput={(params) => <TextField {...params}
                                        label="Rechercher un point de retrait"
                                        variant="outlined"
                                    />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button 
                                    type="submit" 
                                    variant="contained"
                                    color="primary" 
                                    disabled={selectedPartner === undefined} 
                                    disableElevation 
                                    fullWidth>Valider</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Link to={{ pathname: "/" }}>
                                    Retour à l'accueil</Link>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Container>
    );
}

export default PartnerSearch;
