import React from 'react'

import { Container, Typography, Button, Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { useHistory } from 'react-router-dom';

import { ReactComponent as Logo } from '../../assets/img/logo.svg';

const useStyles = makeStyles(theme => ({
  logoContainer : {
    height: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullHeight : {
    height: '100%'
  },
  mb3 : {
    marginBottom: theme.spacing(3)
  }
}));

const ReturnEnd = () => {

	const classes = useStyles();
	const history = useHistory();

	const next = () => {
		history.push('/')
	}
	
	return (
		<Box
      height="100vh">
      <Container 
        className={classes.logoContainer}>
              <Logo />
          	</Container>
          	<Box 
              component="div" 
             	bgcolor="primary.main"
              height="50%">
              	<Container
                	className={classes.fullHeight}>
	                <Box
	                  display="flex"
	                  flexDirection="column"
	                  justifyContent="center"
	                  alignItems="center"
	                  className={classes.fullHeight}
	                  maxWidth="270px"
	                  mx="auto">
	                  	<Box color='white' mb={6}>
	                  		<Typography variant="h1" align='center' color="inherit">Merci de votre confiance, à bientôt !</Typography>
	                  	</Box>
                    	<Button onClick={next}>Retour à l'accueil</Button>
                	</Box> 
              </Container>
          </Box>
      </Box>
		
	);
};

export default ReturnEnd;