import React, { useContext, useEffect, useState } from 'react';

import { Container, Grid, makeStyles, Typography } from '@material-ui/core';

import KlikHeader from '../../components/organisms/KlikHeader';

import PartnerSingleCard from '../../components/layouts/PartnerSingleCard';
import { Vehicule } from '../../models/Vehicule';
import { db, storage } from '../../services/firebase';
import { VehiculeType } from '../../models/VehiculeType';
import { Partner } from '../../models/Partner';
import { DateTime } from 'luxon';
import UpdateContext from '../../contexts/UpdateContext';


const useStyles = makeStyles(theme => ({
  root: {
      width: '250px',
      marginInline: 'auto',
  },
  label: {
      fontSize: '23px'
  },
  bigLabel: {
      fontSize: '50px',
  }
}));

/**
 * Composant demandant la confirmation d'annulation
 */
const UpdateDate = () => {

    const { oldReservation } = useContext(UpdateContext)!
    const [partner, setPartner] = useState<Partner>()
    const [vehicule, setVehicule] = useState<Vehicule>()

    useEffect(() => {
      (async () => {
          const partnerDoc = await db.collection('partners').doc(oldReservation.partnerId).get()
          let partner: Partner = partnerDoc.data() as Partner
          partner.id = oldReservation.partnerId
          setPartner(partner)
      })()
    }, [oldReservation])

    useEffect(() => {
      (async () => {
          const vehiculeDoc = await db.collection('vehicles').doc(oldReservation.vehiculeId).get()
          let vehicule: Vehicule = vehiculeDoc.data() as Vehicule
          vehicule.id = oldReservation.vehiculeId

          const typeDoc = await db.collection('vehicleTypes').doc(vehicule.type).get()
          const type = typeDoc.data() as VehiculeType | undefined
          vehicule = {
              ...vehicule,
              fulltype: type ? {
                  ...type,
                  url: await storage.ref().child('referential/img/' + type.imageLabel + '.jpg')
                      .getDownloadURL(),
              } : undefined,
          }
          setVehicule(vehicule)
      })()
    }, [oldReservation])
    
    const start = DateTime.fromJSDate(oldReservation!.startDate).setLocale('fr')
    const end = DateTime.fromJSDate(oldReservation!.endDate).setLocale('fr')

    const classes = useStyles()

    return (
      <Container>
        <KlikHeader title="Modification de la réservation"
				back={"/reservations"}/>
        <Grid item xs={12}>
          <Typography variant="subtitle1" align="center">{"Réservation initiale :"}</Typography>
        </Grid>
        {start.toLocaleString(DateTime.DATE_FULL) === end.toLocaleString(DateTime.DATE_FULL) 
        ?
        <div>
          <Grid item xs={12}>
            <Typography 
              align="center"className={classes.label}>Le {start.toFormat('dd/LL/yyyy')}</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography 
              align="center"
              className={classes.label}>de {start.toFormat('HH')}h{start.toFormat('mm')} à {end.toFormat('HH')}h{end.toFormat('mm')}</Typography>
          </Grid>
        </div>
        : 
        <div>
          <Grid item xs={12}>
            <Typography 
              align="center"className={classes.label}>Du {start.toFormat('dd/LL/yyyy')} à {start.toFormat('HH')}h{start.toFormat('mm')}</Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography 
            align="center"className={classes.label}> au {end.toFormat('dd/LL/yyyy')} à {end.toFormat('HH')}h{start.toFormat('mm')}</Typography>
          </Grid>
        </div>}
        <Grid item xs={12}></Grid>
        {partner !== undefined && vehicule !== undefined &&
        <PartnerSingleCard
                            vehicule={vehicule}
                            partner={partner}
                            isUpdate={true}
                            reservation={oldReservation}
                            />}
      </Container>
    )
}

export default UpdateDate