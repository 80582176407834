import React, {useCallback, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Box, Button, CardContent, Collapse, Grid, IconButton, Typography} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {makeStyles} from '@material-ui/core/styles'

import KlikCard from '../molecules/KlikCard'
import KlikCardHeaderVehicule from '../organisms/KlikCardHeaderVehicule'
import SlotPicker, {SelectedDates} from '../organisms/SlotPicker/SlotPicker'
import {useBooking} from '../../contexts/BookingContext'
import {functions} from '../../services/firebase'
import { DateTime } from 'luxon'
import { Reservation } from '../../models/Reservation'

type PartnerSingleCardProps = {
    vehicule: any,
    partner: any,
    isUpdate: Boolean,
    reservation: Reservation | undefined
}

const useStyles = makeStyles(theme => ({
    content: {
        paddingTop    : theme.spacing(2),
        paddingBottom : theme.spacing(1),
        '&:last-child': {
            paddingBottom: theme.spacing(1)
        }
    }
}))

/**
 * Composant affichant le processus de selection d'un créneau horaire
 */
const PartnerSingleCard = ({vehicule, partner, isUpdate, reservation}: PartnerSingleCardProps) => {
    const classes = useStyles()
    const history = useHistory()

    const [expanded, setExpanded] = useState<boolean>(false)
    const [value, setValue] = useState<SelectedDates>([undefined, undefined])
    const [vehicleAvailability, setVehicleAvailability] = useState<string[]>([])

    const {setBooking} = useBooking()!

    const handleSlotPickerChange = useCallback((value: (Date | undefined)[]) => {
        setValue(value as SelectedDates)
    }, [])

    useEffect(() => {
        if (!vehicule.id) return

        const getVehicleReservedSlots = functions.httpsCallable('getVehicleReservedSlots')
        getVehicleReservedSlots({vehiculeId: vehicule.id})
            .then((result) => {
                // dans le cas d'une modification, on affiche les créneaux de la réservation à modifier comme disponibles
                if(isUpdate && reservation) {
                    setVehicleAvailability(result.data.filter((date: Date) => {
                        const reservedSlot = DateTime.fromJSDate(new Date(date)).setLocale('fr')
                        return (reservedSlot > DateTime.fromJSDate(reservation.endDate) || reservedSlot < DateTime.fromJSDate(reservation.startDate))
                    }))
                    return
                }
                setVehicleAvailability(result.data)
            })
    }, [vehicule, isUpdate, reservation])

    const onReservation = () => {
        if (value[0] && value[1]) {
            setBooking({
                'start'   : value[0],
                'end'     : value[1],
                'partner' : partner,
                'vehicule': vehicule,
                'oldReservation': reservation,
            })
            if(isUpdate) history.push({pathname: `resume`})
            else history.push({pathname: '/book/resume'})
        }
    }

    return (
        <KlikCard>
            <KlikCardHeaderVehicule
                partnerLabel={partner.label}
                partnerAddress={partner.address}
                vehiculeLabel={vehicule.label}
                typeLabel={vehicule.fulltype.label}
                typeUrl={vehicule.fulltype.url}/>
            <CardContent className={classes.content}>
                <Collapse in={!expanded} timeout="auto" unmountOnExit>
                    <Grid container direction="column" alignItems="center">
                        <Typography variant="body2">Voir les disponibilités</Typography>
                        <IconButton color="primary" onClick={() => setExpanded(true)} size="small">
                            <ExpandMoreIcon/>
                        </IconButton>
                    </Grid>
                </Collapse>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <SlotPicker
                        availability={vehicleAvailability}
                        partner={partner}
                        onChange={handleSlotPickerChange}/>
                    <Box
                        display="flex"
                        justifyContent="center"
                        mt={2} mb={1}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={onReservation}
                            disabled={!value[0] || !value[1]}>Réserver</Button>
                    </Box>
                </Collapse>
            </CardContent>
        </KlikCard>
    )
}

export default PartnerSingleCard