import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Box, Button, CircularProgress } from '@material-ui/core';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements} from '@stripe/react-stripe-js';

import { ReactComponent as CardIcon } from '../../assets/img/CardIcon.svg';

const useStyles = makeStyles({
    StripeElement: {
        padding: "15px 40px 14px 40px",
        background: "#fff",
        borderRadius: "25px"
    },
    subtitle: {
    	color: "#fff"
    }
});

const CardPaymentForm = ({ subtitle, onPayment, buttonLabel, loading } : CardPaymentFormProps) => {

	const classes = useStyles();

	const cardStyle: any = {
	    classes: {
	        base: "StripeElement"
	    }, 
	    style: {
	        base: {
	            backgroundColor: "#fff",
	            color: '#606060',
	            fontWeight: 500,
	            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
	            fontSize: '14px',
	        },
	    }
	};

	const elements = useElements();

	const payement = () => {
		if(elements != null) {
			const cardElement = elements.getElement(CardNumberElement);
			onPayment(cardElement);
		}
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
	            <Box maxWidth="68px" mx="auto">
	                <CardIcon />
	            </Box>
	        </Grid> 
	        <Grid item xs={12}>
	            <Typography align="center" className={classes.subtitle}>{subtitle}</Typography>
	        </Grid>
			<Grid item xs={12}>
			    <CardNumberElement options={cardStyle} className={classes.StripeElement} />
			</Grid> 
			<Grid item xs={12}>
			    <CardExpiryElement options={cardStyle} className={classes.StripeElement} />
			</Grid> 
			<Grid item xs={12}>
			    <CardCvcElement options={cardStyle} className={classes.StripeElement} />
			</Grid>
			{loading ?
			<Box width='100%' height="150px" display='flex' justifyContent='center' alignItems='center'>
					<CircularProgress color="secondary"/>
			</Box>
			:
			<Grid item xs={12}>
					<Button 
							variant="outlined"
							color="default"
							disableElevation 
							fullWidth
							className="form-button" onClick={payement}>{ buttonLabel }</Button>
			</Grid>
			}
		</Grid>
	);
}

export default CardPaymentForm;

type CardPaymentFormProps = {
	onPayment: (cardElement: any) => void,
	subtitle: string,
	buttonLabel: string,
	loading: boolean
}