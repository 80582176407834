import { Box, Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';

type ConfirmationCodeProps = {
    onSubmit : (value: string) => void,
    loading: boolean
}

/** 
 * Récupération du code de confirmation envoyé par SMS
 */
const ConfirmationCode = ({ onSubmit, loading } : ConfirmationCodeProps) => {

	const [value, setValue] = useState<string>("");

	const handleChange = (event: any) => {
        setValue(event.target.value);
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        onSubmit(value)
        e.preventDefault()
    }

	return (
		<Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField variant="outlined" type="text" value={value} onChange={handleChange} label="Code reçu par SMS" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary"  type="submit" fullWidth>{loading ? <CircularProgress color="secondary" /> : "Valider"}</Button>
                </Grid>
            </Grid>
            
        </Box>
	)
}

export default ConfirmationCode