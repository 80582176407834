import React, {useState} from 'react'

import {Box, Button, CircularProgress, Grid, TextField} from '@material-ui/core'

import {nanoid} from 'nanoid'

import KlikFileInput from '../../molecules/KlikFileInput'
import {Profile} from '../../../models/Profile'
import {storage, useLicenceUrl} from '../../../services/firebase'

type BookingLegalInformationsProps = {
    onSubmit: (data: {}) => void
    profile: Profile | null,
    loading: boolean
}

/**
 * Composant affichant le processus de selection d'un créneau horaire
 */
const BookingLegalInformations = ({onSubmit, profile, loading}: BookingLegalInformationsProps) => {
    const [drivingLicenseNumber, setDrivingLicenseNumber] = useState(profile?.drivingLicenseNumber ?? '')
    const [drivingLicenseCity, setDrivingLicenseCity] = useState(profile?.drivingLicenseCity ?? '')
    const previousImage = useLicenceUrl(profile?.drivingLicensePictureId)
    const [drivingLicensePicture, setDrivingLicensePicture] = useState<any>()
    const [drivingLicensePictureId, setDrivingLicensePictureId] = useState<any>(profile?.drivingLicensePictureId)

    const handleDrivingLicenseNumberChange = (event: any) => {
        setDrivingLicenseNumber(event.target.value)
    }

    const handleDrivingLicenseCityChange = (event: any) => {
        setDrivingLicenseCity(event.target.value)
    }

    const handleDrivingLicensePictureChange = (file: any) => {
        if(!file) {
            setDrivingLicensePicture(null)
            setDrivingLicensePictureId(null)
            return
        }
        setDrivingLicensePicture(file)
        setDrivingLicensePictureId(nanoid())
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()

        setDrivingLicensePictureId(profile?.drivingLicensePictureId ?? nanoid())
        const onComplete = () => {
            onSubmit({
                drivingLicensePictureId,
                drivingLicenseNumber,
                drivingLicenseCity
            })
        }
        if (!drivingLicensePicture) {
            onComplete()
            return
        }
        const storageRef = storage.ref().child('drivingLicense').child(drivingLicensePictureId)
        const uploadTask = storageRef.put(drivingLicensePicture)
        uploadTask.then(onComplete)
    }

    return (
        <Grid component="form" container spacing={3} noValidate onSubmit={handleSubmit}>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={drivingLicenseNumber}
                    onChange={handleDrivingLicenseNumberChange}
                    label="Numéro de permis de conduire"/>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={drivingLicenseCity}
                    onChange={handleDrivingLicenseCityChange}
                    label="Lieu de délivrance du permis"/>
            </Grid>
            <Grid item xs={12}>
                <Box my={3}>
                    {(!profile?.drivingLicensePictureId || previousImage) && <KlikFileInput
                        label="Veuillez prendre en photo votre permis de conduire"
                        defaultValue={previousImage}
                        onChange={handleDrivingLicensePictureChange}/>
                    }
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    color="primary"
                    disabled={!drivingLicensePictureId}>{loading ? <CircularProgress color="secondary" /> : "Valider"}</Button>
            </Grid>
        </Grid>
    )
}

export default BookingLegalInformations
