import { CircularProgress } from '@material-ui/core'
import React from 'react'
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import UpdateContext from '../../contexts/UpdateContext';
import { useReservation } from '../../services/firebase';
import UpdateDate from './UpdateDate';
import UpdateEnd from './UpdateEnd';
import UpdatePayment from './UpdatePayment';
import UpdateWaiting from './UpdateWaiting';

const Update = () => {
  const {path, url} = useRouteMatch()
  const params: any = useParams()
  const {loading, reservation} = useReservation({
    id: params.id
  })

  const ctx = {oldReservation: reservation!}

  return (
    <UpdateContext.Provider value={ctx}>
    {loading && <CircularProgress/>}
    {reservation !== undefined &&
      <Switch>
        <Route exact path={`${path}/`}>
            <Redirect to={`${url}/date`}/>
        </Route>
        <Route path={`${path}/date`} component={UpdateDate}/>
        <Route path={`${path}/payment`} component={UpdatePayment}/>
        <Route path={`${path}/end`} component={UpdateEnd}/>
        <Route path={`${path}/waiting`} component={UpdateWaiting}/>
      </Switch>}
      
    </UpdateContext.Provider>
  )
}

export default Update
