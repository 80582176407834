import React from 'react'
import {useHistory} from 'react-router-dom'
import {useAuth} from '../../contexts/UserContext'
import {Box, Grid, IconButton, Typography} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import {ReactComponent as Logo} from '../svg/Logo.svg'
import {makeStyles} from '@material-ui/core/styles'

type KlikHeaderProps = {
    title?: string,
    back?: string,
    account?: boolean
}


const useStyles = makeStyles(theme => ({
    title: {
        color: theme.palette.text.primary,
    }
}))


function KlikHeader({title, back, account = false}: KlikHeaderProps) {
    const {authenticated: auth} = useAuth()
    const history = useHistory()
    const classes = useStyles()

    return (
        <Box
            component="header"
            mb={2}
            mt={3}>
            <Grid container>
                <Grid item xs={2}>
                    {back !== undefined ? <IconButton
                        size="small"
                        onClick={() => history.push(back)}
                        color="default">
                        <ChevronLeftIcon/>
                    </IconButton> : ''}
                </Grid>
                <Grid item xs={8}>
                    {title !== undefined ? <Typography className={classes.title} variant="h2" align="center">
                            {title}
                        </Typography> :
                        <Box component='div' maxWidth="150px" mx='auto'>
                            <Logo/>
                        </Box>}
                </Grid>
                <Grid item xs={2}>
                    {auth && account ? (
                        <IconButton
                            size="small"
                            aria-label="account of current user"
                            onClick={() => history.push('/auth/profile')}
                            color="inherit">
                            <AccountCircle/>
                        </IconButton>
                    ) : ''}
                </Grid>
            </Grid>
        </Box>
    )
}

export default KlikHeader