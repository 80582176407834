import React from 'react'

import {Reservation} from '../models/Reservation'

const UpdateContext = React.createContext<UpdateContextType | undefined>(undefined)

type UpdateContextType = {
    oldReservation: Reservation
}

export default UpdateContext