import React, { useContext } from 'react'

import { Container, Typography, Button, Box, Grid } from '@material-ui/core';

import KlikHeader from '../../components/organisms/KlikHeader'

import { makeStyles } from '@material-ui/core/styles';

import { useHistory } from 'react-router-dom';

import ReturnContext from '../../contexts/ReturnContext';

import { ReactComponent as InspectionIcon } from '../../components/svg/InspectionIcon.svg';

import { contact } from '../../config'

const useStyles = makeStyles(theme => ({
  	listItem : {
		display: 'inline',
		color: '#606060'
	},
	subtitle: {
		color: theme.palette.primary.main,
		fontWeight: 'bold'
	},
	question: {
		color: theme.palette.primary.main,
		fontSize: '16px',
		fontWeight: 'bold'
	}
}));

const ReturnInventory = () => {

	const { reservation } = useContext(ReturnContext)!;
	const history = useHistory();

	const next = () => {
		history.push(`/return/${reservation.id}/picture`)
	}
	const mail = () => {
			window.location.href = 'mailto:' + contact + '?subject=Anomalie sur la remorque (id réservation : ' + reservation.id + ')';
	}
	const classes = useStyles();

	return (
		<Container>			
			<KlikHeader
				back={"/return/" + reservation.id + "/"}
				title="État des lieux" />
			<Box
				display='flex'
				flexDirection='column'
				justifyContent='center'
				alignItems='center'>
				<InspectionIcon />
				<Box width="290px" mt={5}>
					<Typography align='center' className={classes.subtitle}>Voici les points à contrôler pour vous assurer du bon fonctionnement de la remorque : </Typography>
				</Box>
				<Box my={8}>
					<Grid
						container
						direction="column"
						spacing={1}>
						<Grid item>
							<Typography variant="body2" className={classes.listItem}>1. Verrouillage du cadenas</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body2" className={classes.listItem}>2. Propreté de la remorque</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body2" className={classes.listItem}>3. État de la roue de Jockey</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body2" className={classes.listItem}>4. État du câble de sécurité</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body2" className={classes.listItem}>5. État général de la remorque</Typography>
						</Grid>
					</Grid>
				</Box>
				<Typography className={classes.question}>Avez-vous quelque chose à signaler ?</Typography>
				<Box width='260px' mt={3}>
					<Box mb={2}>
						<Button 
							variant="outlined" 
							color="primary"
							fullWidth
							onClick={next}>Rien à signaler</Button>
					</Box>
					<Button 
						variant="contained" 
						fullWidth
						color="primary"
						onClick={mail}>Signaler quelque chose</Button>
				</Box>
			</Box>
		</Container>
	);
};

export default ReturnInventory;