import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Box} from '@material-ui/core';
import KlikCardHeader from '../molecules/KlikCardHeader';

const useStyles = makeStyles(theme => ({
	title: {
		fontWeight: 700,
        marginBottom: theme.spacing(1)
	},
    subtitle: {
        textTransform: 'uppercase',
        marginBottom: theme.spacing(0.5)
    }
}));

type KlikCardHeaderVehiculeProps = {
	partnerLabel : string,
	partnerAddress : string,
	vehiculeLabel : string,
    typeLabel : string,
    typeUrl : string,
}

const KlikCardHeaderVehicule = ({partnerLabel, partnerAddress, vehiculeLabel, typeLabel, typeUrl} : KlikCardHeaderVehiculeProps) => {

	const classes = useStyles();

    return (
        <KlikCardHeader>
        	<Grid container spacing={2}>
                <Grid item xs={8}>
                    <Typography variant="h3" color="inherit" className={classes.title}>Remorque { vehiculeLabel }</Typography>
                    <Typography variant="body2" color="inherit" className={classes.subtitle}>{ partnerLabel }</Typography>
                   	<Typography variant="body2" color="inherit">{ partnerAddress }</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Box display="flex" alignItems="center" flexDirection="column" justifyContent="flex-end" height="100%">  
                        <Box mb={1}>
                            <img src={typeUrl} className="vehicule-icon" alt={typeLabel} width="50px" />
                        </Box>
                        <Typography variant="body2" align="center" color="inherit">{typeLabel}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </KlikCardHeader>
    );
}

/*

TODO : Rajouter l'image du type de la remorque


<Typography>{fulltype?.label}</Typography>

*/

export default KlikCardHeaderVehicule;