import React, { useState } from 'react'

import { Typography } from '@material-ui/core';
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as Smiley1Icon } from '../../components/svg/Smiley1Icon.svg';
import { ReactComponent as Smiley2Icon } from '../../components/svg/Smiley2Icon.svg';
import { ReactComponent as Smiley3Icon } from '../../components/svg/Smiley3Icon.svg';
import { ReactComponent as Smiley4Icon } from '../../components/svg/Smiley4Icon.svg';

const useToogleButtonGroupStyles = makeStyles(theme => ({
    root: {
        background: 'transparent',
        borderRadius: 15,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    groupedHorizontal: {
        '&:not(:first-child)': {
          marginLeft: 0,
          borderLeft: 'none',
          borderTopLeftRadius: 15,
          borderBottomLeftRadius: 15,
        },
        '&:not(:last-child)': {
          borderTopRightRadius: 15,
          borderBottomRightRadius: 15,
        },
    }
}));

const useToogleButtonStyles = makeStyles(theme => ({
    root: {
        border: 'none',
        textTransform: 'none',
        '&$selected': {
        	backgroundColor: theme.palette.primary.main,
        	color: theme.palette.common.white,
        	'&:hover': {
        		backgroundColor: theme.palette.primary.light,
        	}
        },

        '&:hover': {
        	backgroundColor: theme.palette.primary.main,
        	color: theme.palette.common.white,
        	'& p' : {
        		color: theme.palette.common.white
        	}
        },
        lineHeight: 'none',
        borderRadius: '15px',
        color: theme.palette.primary.main
    },
    selected: {
        boxShadow: '0px 0.5px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '15px',
        color: theme.palette.common.white,
        '& p' : {
        	color: theme.palette.common.white
        }
    },
    label: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        lineHeight: 'none',
        width: '56px',
    	'&:hover': {
    		color: theme.palette.common.white,
    	}
    },
}));

const useStyles = makeStyles(theme => ({
	smileyLabel: {
		marginTop: theme.spacing(2)
	}
}));

type KlikSmileyTerminalProps = {
    onChange: (value: number|null) => void
}

const KlikSmileyTerminal = ({onChange} : KlikSmileyTerminalProps) => {

	const toogleButtonGroupClasses = useToogleButtonGroupStyles();
	const toogleButtonClasses = useToogleButtonStyles();

	const [satisfaction, setSatisfaction] = useState<number|null>(null);

	const classes = useStyles();

	const handleSatisfaction = (event: React.MouseEvent<HTMLElement>, newSatifaction: number|null) => {
	    setSatisfaction(newSatifaction);
        onChange(newSatifaction);
	};

	return (
		<ToggleButtonGroup
		    value={satisfaction}
		    classes={toogleButtonGroupClasses}
		    exclusive
		    onChange={handleSatisfaction}
		    aria-label="text alignment">
	      	<ToggleButton
	      		classes={toogleButtonClasses}
	      		value={1} 
	      		aria-label="Très insatisfait">
	      		<Smiley1Icon />
	        	<Typography 
	        		variant="body2" 
	        		className={classes.smileyLabel}>Très insatisfait</Typography>
	      	</ToggleButton>
	      	<ToggleButton 
	      		classes={toogleButtonClasses}
	      		value={2} 
	      		aria-label="Plutôt insatisfait" >
	      		<Smiley2Icon />
	        	<Typography 
	        		variant="body2"
	        		className={classes.smileyLabel}>Plutôt insatisfait</Typography>
	      	</ToggleButton>
	      	<ToggleButton
	      		classes={toogleButtonClasses}
	      		value={3} 
	      		aria-label="Plutôt satisfait">
	      		<Smiley3Icon />
	        	<Typography 
	        		variant="body2"
	        		className={classes.smileyLabel}>Plutôt satisfait</Typography>
	      	</ToggleButton>
	      	<ToggleButton 
	      		classes={toogleButtonClasses}
	      		value={4} 
	      		aria-label="Très satisfait">
	      		<Smiley4Icon />
	        	<Typography 
	        		variant="body2"
	        		className={classes.smileyLabel}>Très satisfait</Typography>
	      	</ToggleButton>
    	</ToggleButtonGroup>
		
	);
};

export default KlikSmileyTerminal;