import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button } from '@material-ui/core'
import { DateTime } from 'luxon'

import SlotPickerBound from './SlotPickerBound'
import SlotPickerDate from './SlotPickerDate'
import SlotPickerTime from './SlotPickerTime'
import { now, startOfDay } from '../../../services/date'
import { functions } from '../../../services/firebase'
import { Partner } from '../../../models/Partner'

export type SelectedDates = [Date | undefined, Date | undefined]

type SlotPickerProps = {
    availability: string[],
    partner: Partner,
    onChange: (value: (Date | undefined)[]) => void
}

enum State {
    step1,
    step2,
    step3
}

function formatDate(date: DateTime): string {
    return date.setZone('Europe/Paris').toISO()
}

const hoursInADay = 24

const SlotPicker = ({ availability, partner, onChange }: SlotPickerProps) => {
    const [start, setStart] = useState<DateTime | undefined>()
    const [end, setEnd] = useState<DateTime | undefined>()
    const [closingHours, setClosingHours] = useState<string[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const [date, setDate] = useState<DateTime>(() => now())

    const nextUnavailability = useMemo(() => findNextUnavailability({ availability, start }), [availability, start])

    useEffect(() => {
        setLoading(true)
        const getVehiclePartnerClosingHours = functions.httpsCallable('getVehiclePartnerClosingHours')
        getVehiclePartnerClosingHours({ day: date.weekday, partnerId: partner.id })
            .then((result) => {
                setClosingHours(result.data)
                setLoading(false)
            })
        // eslint-disable-next-line 
    }, [date])

    const state = useMemo(() => {
        return !start
            ? State.step1
            : !end
                ? State.step2
                : State.step3
    }, [start, end])

    useEffect(() => {
        onChange([start?.toJSDate(), end?.toJSDate()])
    }, [start, end, onChange])


    const [available, selected] = useMemo(() => {
        const today = now()
        const startOfCurrentDay = startOfDay(date)

        const hours = new Array(hoursInADay).fill(false)
            .map((_, index) => {
                return startOfCurrentDay.plus({ hours: index })
            })

        const startedTheSelection = !!start
        const waitingForEndSelection = !end
        const startedButNotEndedSelection = startedTheSelection && waitingForEndSelection

        const available = hours
            .map((item) => {
                return item >= today &&
                    !isPartnerClosed(closingHours, item, partner) &&
                    !availability.includes(formatDate(item)) &&
                    (!startedButNotEndedSelection || item > start!) &&
                    (!nextUnavailability || (startedButNotEndedSelection && item < nextUnavailability))
            })

        const selected = hours
            .map((item) => {
                return startedTheSelection && isInSelectionRange(item, start!, end)
            })
        return [available, selected]
        // eslint-disable-next-line 
    }, [date, state, start, end, nextUnavailability, closingHours, availability])

    const changeDate = (value: DateTime) => {
        setDate(value)
    }

    const changeTime = (value: number) => {
        const newDateTime = value > 4 ? date.set({ hour: value }) : date.set({ hour: value }).plus({ days: 1 })
        switch (state) {
            case State.step1:
                setStart(newDateTime)
                break
            case State.step2:
                setEnd(newDateTime)
                break
            case State.step3:
                setStart(newDateTime)
                setEnd(undefined)
                break
        }
    }

    const reset = () => {
        setStart(undefined)
        setEnd(undefined)
    }

    return (
        <Box>
            <SlotPickerBound
                value={state === State.step1 ? 'start' : 'end'}
                start={start}
                end={end} />
            <SlotPickerDate
                value={date}
                onChange={(value) => changeDate(value)} />
            <SlotPickerTime
                selected={selected}
                available={available}
                onChange={(value) => changeTime(value)}
                loading={loading} />
            <Box mt={1}>
                <Button onClick={reset} size="small">Effacer les dates</Button>
            </Box>
        </Box>
    )
}

export default SlotPicker

type findNextUnavailabilityProps = {
    availability: string[],
    start: DateTime | undefined,
}

function isInSelectionRange(item: DateTime, start: DateTime, end: DateTime | undefined): boolean {
    return item.equals(start) || (!!end && (item > start && item <= end))
}

const findNextUnavailability = ({ availability, start }: findNextUnavailabilityProps): DateTime | undefined => {
    if (!start) return undefined

    const startAsIso = formatDate(start)
    const foundNextUnavailability = availability.find((date) => date > startAsIso)

    if (!foundNextUnavailability) return undefined

    return DateTime.fromISO(foundNextUnavailability)
}

const isPartnerClosed = (closingHours: any, item: any, partner: any): boolean => {
    const date = `${pad(item.day.toString())}/${pad(item.month.toString())}/${item.year}`
    const closingDays = partner.days !== [] && partner.days !== undefined ? partner.days.split(/,[ ]/) : []
    return item.hour < parseInt(closingHours[0]) || item.hour >= parseInt(closingHours[1]) || closingDays.includes(date) 
}

const pad = (number: string) => {
    return number.length < 2 ? '0' + number : number
}