import React from 'react'

import { Container, Typography, Grid, Button } from '@material-ui/core'

import { useHistory, useParams } from 'react-router-dom'

import KlikHeader from '../../components/organisms/KlikHeader'
import { contact } from '../../config'

/**
 * Composant permettant au client de contacter directement Remorc'heure quand il se retrouve dans une situation bien particulière : suite à plusieurs modifications, il souhaite se faire rembourser un montant supérieur à ce qu'il à payé lors de la dernière modification 
 **/
function UpdateWaiting() {

    const history = useHistory()
    const {id} = useParams<{ id: string }>()

    const mail = () => {
			window.location.href = 'mailto:' + contact + '?subject=Modification et remboursement partiel d\'une réservation (id réservation : ' + id + ')';
	}
    const next = () => {
        history.push(`/reservations`)
    }

    return (
      <Container>
      <KlikHeader title="Contacter Remorc'heure"/>
      <Grid item xs={12}>
        <Typography variant="subtitle1" align="center">{"Veuillez contacter le support en cliquant sur le bouton et expliquer la manipulation que vous souhaitez réaliser. Vous serez recontacté(e) dans les plus brefs délais afin de procéder à la modification et au remboursement de la différence."}</Typography>
      <Button 
						variant="contained" 
						fullWidth
						color="primary"
						onClick={mail}>Contacter le support</Button>
      </Grid>

      <Grid item xs={12}>
        <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={next}>Retour à mes réservations</Button>
      </Grid>
    </Container>
    )
}

export default UpdateWaiting
