import React from 'react';
import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	root: {
		background: '#F5F5F5',
		boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
		borderRadius: '15px'
	},
	primary: {
		background: '#F7A512',
		color: '#fff'
	}
});


const KlikCard = ({children, color, ...rest } : any) => {

	const classes = useStyles();

    return (
        <Card 

        className={`
	        ${classes.root}
	        ${color === "primary" ? classes.primary : ''}
	    `}

        {...rest}>
            {children}
        </Card>
    );
}

export default KlikCard;

