import React, { useState } from 'react';

import { Grid, IconButton, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';

import { DateTime } from 'luxon'

import { isAfterToday } from '../../../services/date'

const useStyles = makeStyles(theme => ({
    root: {
        paddingBlock: theme.spacing(2) + 'px'
    },
    label: {
        fontWeight: 'bold',
        textTransform: 'capitalize'
    }
}));

type SlotPickerDateProps = {
    value: DateTime,
	onChange : (value: DateTime) => void,
}

const SlotPickerDate = ({value, onChange} : SlotPickerDateProps) => {

	const classes = useStyles()

    const [date, setDate] = useState<DateTime>(value);

	const changeDate = (increment: number) => {
        const nd = date.plus({ days: increment});
        onChange(nd);
        setDate(nd);
	}

	return (
		<Grid container alignItems="flex-end" justify="center" className={classes.root}>
            <Grid item>
                { isAfterToday(date) &&
                	<IconButton color="primary" onClick={_ => changeDate(-1)} size="small">
                        <ChevronLeftRoundedIcon /></IconButton> }
            </Grid>
           	<Grid item xs={6}>
                <Typography align="center" className={classes.label}>
                    { date.toLocaleString({ weekday: 'long', month: 'long', day: 'numeric'}) }</Typography>
            </Grid>
            <Grid item>
                <IconButton color="primary" onClick={_ => changeDate(+1)} size="small">
                    <ChevronRightRoundedIcon /></IconButton>
            </Grid>
        </Grid>
	);
}

export default SlotPickerDate