import React, { useContext, useState } from 'react'
import { Container, Typography, Button, Box } from '@material-ui/core';
import KlikHeader from '../../components/organisms/KlikHeader'
import KlikFileInput from '../../components/molecules/KlikFileInput'
import { useHistory } from 'react-router-dom';
import ReturnContext from '../../contexts/ReturnContext';
import {db, storage} from '../../services/firebase'
import {nanoid} from 'nanoid'

const ReturnPadlock = () => {

	const { reservation } = useContext(ReturnContext)!;
	const history = useHistory();
	const [picture, setPicture] = useState<boolean>(false)
	const [pictureId] = useState<string>(nanoid())

	const next = async () => {
		const reservationRef = db.collection('reservations').doc(reservation.id)

		await db.runTransaction(async (transaction) => {
				transaction.update(reservationRef, {
						returnPadlockPictureId: pictureId,
						hasBeenReturned: true
				})
		})

		history.push(`/return/${reservation.id}/satisfaction`)
	}

	const handlePictureChange = (files?: File[]) => {
		if (!files) {
			setPicture(false)
			return
		}
		Promise.all(
			([] as File[]).concat(files).map(async file => {
				const picturesRef = storage.ref('reservations').child(reservation.id).child('return').child('padlock')
				await picturesRef.child(pictureId)
					.put(file)
				setPicture(true)
			})
		)
  }

	return (
		<Container>			
			<KlikHeader
				back={"/return/" + reservation.id + "/picture"}
				title="Verrouillage du cadenas" />
			<Box width='260px' mx='auto' pt={4}>
				<Typography align='center'>Veuillez prendre une photo de la remorque de la remorque verouillée à son point d’attache.<br/><br/>
				La photo doit permettre de voir que le cadenas est bien verouillé et attaché.</Typography>
				<Box my={8}>
					<KlikFileInput label="Prendre une photo" onChange={handlePictureChange} />
				</Box>
				<Button 
					variant="contained" 
					color="primary"
					fullWidth
					disabled={!picture}
					onClick={next}>Suivant</Button>
			</Box>
		</Container>
	);
};

export default ReturnPadlock