import React from 'react'
import {Chip, Grid, Box, CircularProgress} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

const HOURS = new Array(24).fill(0).map((_, index) => (index) % 24)

const useChipStyles = makeStyles(theme => ({
    root                   : {
        width           : '100%',
        borderRadius    : '40px',
        color           : '#606060',
        paddingTop      : '6px',
        paddingBottom   : '6px',
        height          : 'auto',
        border          : '4px solid transparent',
        '&.Mui-disabled': {
            opacity: 1
        }
    },
    colorPrimary           : {
        color: 'white'
    },
    colorSecondary         : {
        color     : '#606060',
        background: '#FFD78E'
    },
    clickableColorSecondary: {
        '&:hover': {
            backgroundColor: '#FFD78E',
            borderColor    : theme.palette.primary.main,
            color          : '#606060',
        },
        '&:focus': {
            backgroundColor: '#FFD78E',
        }
    },
    label                  : {
        fontSize: 12
    }
}))

type SlotPickerTimeProps = {
    available: Array<boolean>,
    selected: Array<boolean>,
    onChange: (value: number) => void,
    loading: Boolean
};

const SlotPickerTime = ({selected, available, onChange, loading}: SlotPickerTimeProps) => {

    const chipClasses = useChipStyles()

    const getState = (hour: number, index: number): 'primary' | 'secondary' | 'default' | undefined => {
        if (selected[index]) {
            return 'primary'
        } else if (available[index]) {
            return 'secondary'
        }

        return 'default'
    }

    return (
        <div>
        {loading ?
            <Box width='100%' height="150px" display='flex' justifyContent='center' alignItems='center'>
				<CircularProgress color="secondary"/>
                <div>{loading}</div>
			</Box>
			:
            <Grid container spacing={1}>
            {HOURS.map((hour, index) =>
                <Grid key={hour} item xs={3} md={2}>
                    <Chip
                        classes={chipClasses}
                        color={getState(hour, index)}
                        onClick={_ => onChange(hour)}
                        disabled={!available[index]}
                        label={`${hour.toLocaleString('fr', {minimumIntegerDigits: 2})}:00`}/>
                <div>{loading}</div>

                </Grid>
                
            )}
        </Grid>
        }
        </div>
    )
}

export default SlotPickerTime