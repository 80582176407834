import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Grid, Typography, TextField, Divider, Box, CircularProgress, Container, CardContent, Checkbox, FormControlLabel, Link } from '@material-ui/core';
import { db } from '../../services/firebase'

import { usePricing } from '../../services/firebase'

import KlikHeader from '../../components/organisms/KlikHeader'
import KlikCard from '../../components/molecules/KlikCard'
import KlikCardHeaderVehicule from '../../components/organisms/KlikCardHeaderVehicule'

import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as FromToIcon } from '../../components/svg/FromToIcon.svg';

import { DateTime } from 'luxon';
import { Referential } from '../../models/Referential'

import { useBooking } from '../../contexts/BookingContext';


export const toFormatDate = (date: DateTime) => {
    return date.toLocaleString(DateTime.DATE_SHORT);
}

export const toFormatHours = (date: DateTime) => {
    return date.toLocaleString(DateTime.TIME_24_SIMPLE);
}

const useStyles = makeStyles({
    bold: {
        fontWeight: 'bold'
    },
    hourLabel: {
        fontWeight: 'bold',
        fontSize: 20
    },
    footer: {
        fontSize: '12px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: '12px'
    },
    link: {
        fontSize: '12px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: '12px',
        cursor: 'pointer'
    }
});

/**
 * Composant affichant le résultat de la réservation 
 */
function BookResume() {

    const classes = useStyles()

    const { booking, loaded } = useBooking()!

    const history: any = useHistory()

    const [assuranceInfo, setAssuranceInfo] = useState("");

    // const [isFreeHoursActivated, setIsFreeHoursActivated] = useState(false);

    useEffect(() => {
        // Récupération du lien des conditions générales
        db.collection('referential').get().then((querySnapshot: any) => {
            const referential: Referential = querySnapshot.docs[0].data();
            setAssuranceInfo(referential.assuranceInfo);
        })
    }, [assuranceInfo]);

    const [assurance, setAssurance] = useState(true)
    const toggleAssurance = () => setAssurance(!assurance)


    const start: DateTime = DateTime.fromJSDate(booking!.start)
    const end: DateTime = DateTime.fromJSDate(booking!.end)

    const partner = booking!.partner

    const [discountKey] = useState(partner.discount?.field)
    const [discountCode, setDiscountCode] = useState("")
    const [newDiscountCode, setNewDiscountcode] = useState("")
    const [isDiscountCodeValid, setDiscountCodeValidFlag] = useState(false)


    const vehicule = booking!.vehicule

    const { error, loading, pricing } = usePricing({ 'startDate': start.toISO(), 'endDate': end.toISO(), 'partnerId': partner!.id, 'withInsurance': assurance, 'discountKey': discountKey, 'discountCode': discountCode })

    const [priceToPay, setPriceToPay] = useState(0);

    // Update local reflected price after firebase init
    useEffect(() => {
        if (pricing?.finalPrice !== undefined) {
            setPriceToPay(pricing?.finalPrice)
        }
        if (pricing !== undefined && pricing?.partnerDiscountAmount > 0) {
            setDiscountCodeValidFlag(true)
        }
        else {
            setDiscountCodeValidFlag(false)
        }

    }, [pricing])

    const handleChange = (event: any) => {
        if (event.target.value.length >= partner.discount.discountFieldMinSize){
            setDiscountCode(event.target.value)
        }
        setNewDiscountcode(event.target.value)
        
    }

   

    const goToPayment = () => {
        if (pricing !== undefined) {
            localStorage.setItem("pricingGenerator", JSON.stringify({ 'startDate': start.toISO(), 'endDate': end.toISO(), 'partnerId': partner!.id, 'withInsurance': assurance, 'discountKey': discountKey, 'discountCode': discountCode }))
            history.push("/book/payment")
        }
    }



    const Row = ({ left, right, bold = false }: any) => {
        return (
            <Grid container>
                <Grid item xs={8}>
                    <Typography align="left" className={bold ? classes.bold : ''}>{left}</Typography></Grid>
                <Grid item xs={4}>
                    <Typography align="right" className={bold ? classes.bold : ''}>{right}</Typography></Grid>
            </Grid>
        )
    }

    if (loaded && booking === undefined) {
        history.push('/partners');
    }
    if (!loaded) return (
        <Box width='100%' height="150px" display='flex' justifyContent='center' alignItems='center'>
            <CircularProgress color="secondary" />
        </Box>
    )


    return (
        <Container>
            <KlikHeader title="Règlement" back={"/partners/" + partner!.id} />
            <KlikCard>
                <KlikCardHeaderVehicule
                    partnerLabel={partner.label}
                    partnerAddress={partner.address}
                    vehiculeLabel={vehicule.label}
                    typeLabel={vehicule!.fulltype!.label}
                    typeUrl={vehicule!.fulltype!.url!} />
                <CardContent>
                    <Box mb={3}>
                        <Typography align="center" className={classes.bold}>Récapitulatif de votre réservation</Typography>
                    </Box>
                    <Grid container>
                        <Grid item xs={4}>
                            <Typography align="center">{toFormatDate(start)}</Typography>
                            <Typography align="center" className={classes.hourLabel}>{toFormatHours(start)}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Box display="flex" alignContent="center" alignItems="center" height="100%" width="100%">
                                <Box margin="auto">
                                    <FromToIcon />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography align="center">{toFormatDate(end)}</Typography>
                            <Typography align="center" className={classes.hourLabel}>{toFormatHours(end)}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardContent>
                    <Box mt={2}>
                        {partner.discount !== undefined &&
                            <Box component="form" >
                                {/* <Box mb={3}>
                                <Typography align="center" className={classes.bold}>Membres club IKEA Family</Typography>
                            </Box> */}
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={12} md={4} >
                                        <Typography align="left" >{partner.discount.fieldName}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8} >
                                        <TextField
                                            key="ikea-card-number"
                                            variant="outlined"
                                            error={!isDiscountCodeValid}
                                            onChange={handleChange}
                                            onBlur={handleChange}
                                            size="small"
                                            type="text"
                                            value={newDiscountCode}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} spacing={2}>
                                    <Typography variant="caption">{partner.discount.discountInfo}</Typography>
                                    <Link align="center" className={classes.link} onClick={() => {
                                        (window as any).open( partner.discount.discountLink, '_blank').focus();
                                    }
                                    } >Plus d'informations
                                    </Link>
                                </Grid>
                                {/* <Grid item xs={12}>
            
            </Grid> */}

                            </Box>
                        }
                    </Box>
                    <Box mb={3}>
                        <Typography align="center" className={classes.bold}>Tarif de votre réservation</Typography>
                    </Box>
                    {loading && <Box width='100%' height="150px" display='flex' justifyContent='center' alignItems='center'>
                        <CircularProgress />
                    </Box>}
                    {pricing !== undefined && <Box>
                        <Row
                            left={"Prix de la location (" + pricing.totalRentDurationHours + "h)"}
                            right={pricing.basePrice + "€"} />
                        {/* {pricing.daysLocation > 0 &&
                            <Row
                                left={"Prix de la location jours supplémentaires (" + pricing.daysLocation + "j)"}
                                right={pricing.locationPriceSupDay + "€"} />
                        } */}
                        {pricing.freeHours > 0 &&
                            <Row
                                left={"Durée de gratuité (" + pricing.freeHours + "h)"}
                                right={"-" + pricing.freeHoursAmount + "€"} />
                        }
                        {pricing.partnerDiscountAmount > 0 &&
                            <Row
                                left={partner.discount.discountName}
                                right={"-" + pricing.partnerDiscountAmount + "€"} />
                        }

                        <Row
                            left="Frais de réservation"
                            right={pricing.reservationFees + "€"} />
                        <FormControlLabel
                            control={<Checkbox />}
                            label="Je souscris à l'assurance rachat de franchise en cas de dommage"
                            checked={assurance}
                            onChange={toggleAssurance}
                        />
                        <Link className={classes.footer} href={assuranceInfo}>En savoir plus sur le rachat de franchise</Link>

                        {assurance && <Row
                            left={"Frais d'assurance (" + pricing.insuranceDuration + "j)"}
                            right={pricing.insurancePrice + "€"} />
                        }
                        <Box mt={2}>
                            <Row
                                left="TOTAL"
                                right={priceToPay + "€"}
                                bold />
                        </Box>
                    </Box>
                    }
                    {!loading &&
                        <Box mt={3} display="flex" justifyContent="center">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={goToPayment}>Passer au règlement</Button>
                        </Box>}
                    {error && <div>Error message</div>}
                </CardContent>
            </KlikCard>
        </Container>
    );
}

export default BookResume;