import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	    background: "#F7A512",
	    color: "#fff"
	}
}));

const KlikCardHeader = ({children, ...rest } : any) => {

	const classes = useStyles();

    return (
        <CardContent className={classes.root} {...rest}>
            {children}
        </CardContent>
    );
}

export default KlikCardHeader;