import React, {FunctionComponent, useContext, useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import firebase from 'firebase'
import {Profile} from '../models/Profile'
import {auth, db} from '../services/firebase'

type UserAuthContext = {
    user: firebase.User | null,
    profile: Profile | null,
    authenticated: boolean,
}

const UserContext = React.createContext<UserAuthContext>({
    user         : null,
    profile      : null,
    authenticated: false,
})

export const useAuth = () => useContext(UserContext)

export const UserProvider: FunctionComponent = ({children}) => {
    const location = useLocation()
    const history = useHistory()
    const [user, setUser] = useState<firebase.User | null>(null)
    const [profile, setProfile] = useState<Profile | null>(null)
    const authenticated = user != null

    useEffect(() => {
        return auth.onAuthStateChanged((nextUser) => {
            setUser(nextUser)
            if (nextUser != null && user == null) {
                history.push((location.state as any)?.from?.pathname)
            }
        })
    }, [user, location.state, history])

    useEffect(() => {
        if (!user) {
            setProfile(null)
            return
        }

        return db.collection('users').doc(user.uid)
            .onSnapshot((profileSnap) => {
                const profile: Profile = profileSnap.data() ?? {}
                setProfile(profile)
            })
    }, [user])

    useEffect(() => {
        if (!user || !profile) return
        if (user.phoneNumber !== profile.phoneNumber) {
            const profileRef = db.collection('users').doc(user.uid)
            profileRef
                .update({
                    phoneNumber: user.phoneNumber
                })
                .catch((e) => {
                    console.warn(e)
                    return profileRef.set({
                        phoneNumber: user.phoneNumber
                    })
                })
        }
    }, [user, profile])

    return <UserContext.Provider value={{
        user,
        profile,
        authenticated,
    }}>
        {children}
    </UserContext.Provider>
}

export const logout = () => auth.signOut()

export default UserContext