import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Box, Button, CardContent, Container, Typography } from '@material-ui/core';
import { useStripe } from '@stripe/react-stripe-js';

import KlikCard from '../../components/molecules/KlikCard'
import KlikHeader from '../../components/organisms/KlikHeader'

import CardPaymentForm from '../../components/layouts/CardPaymentForm'
import {db, functions} from '../../services/firebase'
import { firestore } from 'firebase';
import firebase from 'firebase';

/**  
 * Composant affichant le payement
 */

function UpdatePayment(props: any) {

    console.log('Render UpdatePayment');

    const { id } = useParams<{ id: string }>()
    const history = useHistory()!

    const [amount, setAmount] = useState()
    const [start, setStart] = useState()
    const [end, setEnd] = useState()
    const [pricing, setPricing] = useState<any>()

    const stripe = useStripe();
    const createPaymentIntent = functions.httpsCallable('createPaymentIntent')
    const [loading, setLoading] = useState<boolean>(false)
    const [errorPayment, setErrorPayment] = useState<boolean>(false)

    useEffect(() => {
		setStart(JSON.parse(localStorage.getItem("start")!))
		setEnd(JSON.parse(localStorage.getItem("end")!))
        setPricing(JSON.parse(localStorage.getItem("pricing")!))
        setAmount(JSON.parse(localStorage.getItem("amount")!))
	}, [])

    /**
     * Fonction permettant de réaliser le paiement
     */
    const proceedToPayment = (cardElement : any) => {
        setLoading(true) 
        if (cardElement != null) {

            // Création de la demande de paiement
            createPaymentIntent({
                amount: amount,
                captureLater: false }).then((result) => {

                // Confirmation immédiate du paiement
                stripe?.confirmCardPayment(result.data[0].client_secret, {
                    payment_method: {
                        card: cardElement
                    }
                }).then(async (res) => {
                    setLoading(false)
                    if (res.error) {
                        console.log("Echec du paiement", res.error)
                        setErrorPayment(true)
                    } else {
                        const reservation = db.collection('reservations').doc(id)
                        await db.runTransaction(async (transaction) => {
                            transaction.update(reservation, {
                                startDate : firestore.Timestamp.fromDate(new Date(start!)),
                                endDate : firestore.Timestamp.fromDate(new Date(end!)),
                                paymentId : result.data[0].id,
                                hoursNumberFirstDay: pricing.hoursNumberFirstDay,
                                freeHoursNumber: pricing.freeHoursNumber,
                                totalDiscount : pricing.totalDiscount,
                                locationPriceFirstDay: pricing.locationPriceFirstDay,
                                supDaysLocation: pricing.daysLocation,
                                locationPriceSupDay: pricing.locationPriceSupDay,
                                daysCoverage: pricing.daysCoverage,
                                coveragePrice: pricing.coveragePrice,
                                reservationPrice: pricing.reservationPrice,
                                total: pricing.total,
                                updated: firebase.firestore.FieldValue.increment(1)
                            })
                        })
                        localStorage.setItem("hasBeenRefunded", JSON.stringify(false))
                        history.push("end")
                    }
                })
            })
        }
    }

    return (
        <Container>
            <KlikHeader title="Règlement" back={"/update/" + id + "/resume"} />
            <KlikCard color="primary">
                <CardContent>
                    {errorPayment
                    ?
                    <Box color='white' mb={6}>
                        <Typography variant="h1" align='center' color="inherit">Echec du paiement</Typography>
                        <Button component={Link} to="/">Revenir à l'accueil</Button>
                    </Box>
                    :
                    <div>
                    { amount && <CardPaymentForm 
                        subtitle={`Vous allez payer la somme de ${amount}€.`}
                        onPayment={proceedToPayment} 
                        buttonLabel="Payer"
                        loading={loading} />
                    }
                    </div>
                    }
                </CardContent>
            </KlikCard>
        </Container>
    );
}

export default UpdatePayment
