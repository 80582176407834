import VehiculeCheckSteps from '../../assets/doc/VehiculeCheckSteps.json';
import React, { useState, useContext } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { Container, Box, Grid, Button } from '@material-ui/core';

import KlikHeader from '../../components/organisms/KlikHeader';

import { useHistory } from 'react-router-dom';

import UnlockContext from '../../contexts/UnlockContext';
import {db, storage} from '../../services/firebase'
import { contact } from '../../config'

/**
 * Classe représentant les informations nécessaires à chaque étape de l'état des lieux
 */
class StepInfo {
    // Titre
    title: string = "";

    // Contenu
    content: string = "";

    // Lien vers l'image
    img: string = "";
}

const stepsContent = JSON.parse(JSON.stringify(VehiculeCheckSteps));

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        button: {
            marginTop: theme.spacing(10),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);

function getSteps() {
    return ['étape 1', 'étape 2', 'étape 3', 'étape 4', 'étape 5'];
}

/**
 * Récupération du contenu de chaque étape
 * @param step numéro de l'étape
 */
function getStepContent(step: number): StepInfo {
    return stepsContent[step.toString()];
}

/**
 * Composant permettant de faire l'état des lieux de la remorque au début de la location
 */
function UnlockCheck() {

    const { reservation } = useContext(UnlockContext)!;

    const history = useHistory();

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const next = async () => {
        if(activeStep === steps.length - 1) {
            if(reservation != null) {
                const reservationRef = db.collection('reservations').doc(reservation.id)
                await db.runTransaction(async (transaction) => {
                    transaction.update(reservationRef, {
                        hasBeenUnlocked: true
                    })
                })
                history.push(`/unlock/${reservation.id}/code`);
            }
        } else {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
    }

	const mail = () => {
        window.location.href = 'mailto:' + contact + '?subject=Anomalie sur la remorque (id réservation : ' + reservation.id + ') : ' + getStepContent(activeStep).title ;
    }

    return (
        <Container>
            <KlikHeader title="État des lieux" />
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <CheckStep content={getStepContent(activeStep)} />
            <Grid container spacing={3} className={classes.button}>
                <Grid item xs={6}>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        fullWidth
                        onClick={mail}>Non</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        fullWidth
                        onClick={next}>Oui</Button>
                </Grid>
            </Grid>
        </Container>
    );
}

function CheckStep(props: any) {

    const imgPath = "referential/img/vehicule-check/";

    // Url firebase de l'image associée à l'étape
    const [imageUrl, setImageUrl] = useState("");
    const [responsiveImageUrl, setResponsiveImageUrl] = useState("");

    // Récupération de l'image associée à l'étape de vérification
    storage.ref().child(imgPath + props.content.img).getDownloadURL().then((url: any) => {
        setImageUrl(url);
    })

    storage.ref().child(imgPath + props.content.imgMobile).getDownloadURL().then((url: any) => {
        setResponsiveImageUrl(url + " 1100w");
    })

    return (
        <Grid container spacing={2} direction="column" justify="center">
            <Grid item xs={12}>
                <Typography align="center">{props.content.title}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Box >
                    <img srcSet={responsiveImageUrl} 
                        sizes="(max-width: 600px) 280px,
                        600px"
                        width="100%"
                    src={imageUrl} alt="Illustration de l'élément à vérifier" />
                </Box>
            </Grid>
        </Grid>
    );
}

export default UnlockCheck;
