import React, { useContext, useState } from 'react'
import { nanoid } from 'nanoid'
import { useHistory } from 'react-router-dom'
import { Box, Button, Container, Typography } from '@material-ui/core'
import KlikHeader from '../../components/organisms/KlikHeader'
import KlikFileInput from '../../components/molecules/KlikFileInput'
import ReturnContext from '../../contexts/ReturnContext'
import { db, storage } from '../../services/firebase'

const ReturnPicture = () => {

    const { reservation } = useContext(ReturnContext)!
    const history = useHistory()
    const [picture, setPicture] = useState<boolean>(false)
    const [pictureId] = useState<string>(nanoid())

    const next = async () => {
        const reservationRef = db.collection('reservations').doc(reservation.id)
        await db.runTransaction(async (transaction) => {
            transaction.update(reservationRef, {
                returnPictureId: pictureId
            })
        })
        history.push(`/return/${reservation.id}/padlock`)
    }

    const handlePictureChange = (files?: File[] | File) => {
        if (!files) {
            setPicture(false)
            return
        }
        Promise.all(
            ([] as File[]).concat(files).map(async file => {
                const picturesRef = storage.ref('reservations/').child(reservation.id).child('return').child('pictures')
                await picturesRef.child(pictureId)
                    .put(file)
                setPicture(true)
            })
        )
    }

    return (
        <Container>
            <KlikHeader
                back={'/return/' + reservation.id + '/inventory'}
                title="État général de la remorque" />
            <Box width='260px' mx='auto' pt={4}>
                <Typography align='center'>Veuillez prendre une photo globale de la remorque.<br /><br />
                    Celle-ci nous permettra de voir l’état dans lequel la remorque à été rendue.</Typography>
                <Box my={8}>
                    <KlikFileInput label="Prendre une photo" onChange={handlePictureChange} />
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!picture}
                    onClick={next}>Suivant</Button>
            </Box>
        </Container>
    )
}

export default ReturnPicture