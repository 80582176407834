import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Box, Button, CardContent, Container, Typography } from '@material-ui/core';
import { useStripe } from '@stripe/react-stripe-js';
// import { useAuth } from '../../contexts/UserContext'


import KlikCard from '../../components/molecules/KlikCard'
import KlikHeader from '../../components/organisms/KlikHeader'

import CardPaymentForm from '../../components/layouts/CardPaymentForm'
import { functions } from '../../services/firebase'
import { usePricing } from '../../services/firebase'


/**  
 * Composant affichant le payement
 */

function BookPayment(props: any) {
    
    // const { user, profile } = useAuth()

    const history = useHistory();


    const [pricingGenerator, setPricingGenerator] = useState<any>({
        startDate: null,
        endDate: null,
        partnerId: null,
        withInsurance: null,
        discountKey: null,
        discountCode: null,
    })
    useEffect(() => {
        let pricingGenerator = localStorage.getItem("pricingGenerator")
        if (pricingGenerator !== null) {
            pricingGenerator = JSON.parse(pricingGenerator)
            setPricingGenerator(pricingGenerator)
        }
    }, [])

    const { pricing } = usePricing({ 'startDate': pricingGenerator.startDate, 'endDate': pricingGenerator.endDate, 'partnerId': pricingGenerator.partnerId, 'withInsurance': pricingGenerator.withInsurance, 'discountKey': pricingGenerator.discountKey, 'discountCode': pricingGenerator.discountCode })

    // const [amount, setAmount] = useState()
    // const [assurance, setAssurance] = useState(false)
    // const [pricing, setPricing] = useState<any>(null)

    // useEffect(() => {
    // 	setAssurance(JSON.parse(localStorage.getItem("assurance")!))
    //     setPricing(JSON.parse(localStorage.getItem("pricing")!))
    //     setAmount(JSON.parse(localStorage.getItem("amount")!))
    // }, [])

    const stripe = useStripe();
    var createPaymentIntent = functions.httpsCallable('createPaymentIntent')
    var validatePaymentIntent = functions.httpsCallable('validatePaymentIntent')

    const [loading, setLoading] = useState<boolean>(false)
    const [errorPayment, setErrorPayment] = useState<boolean>(false)

    /**
     * Fonction permettant de réaliser le paiement
     */
    const proceedToPayment = (cardElement: any) => {
        if (cardElement != null) {

            // Création de la demande de paiement
            setLoading(true)
            createPaymentIntent({
                amount: pricing?.finalPrice,
                captureLater: false
            }).then((result) => {

                // Confirmation immédiate du paiement
                if (!result.data.error) {
                    stripe?.confirmCardPayment(result.data.stripePaymentIntent.client_secret, {
                        payment_method: {
                            card: cardElement
                        }
                    }).then((res) => {
                        setLoading(false)
                        if (res.error) {
                            console.log("Echec du paiement", res.error)
                            setErrorPayment(true)
                        } else {
                            console.log("result", result)
                            validatePaymentIntent({ paymentIntentId: result.data.intentId }).then(validationResult => {
                                console.log("validated", validationResult)
                            })
                            localStorage.setItem("paymentIntentId", result.data.intentId)
                            history.push("/book/info")
                        }
                    })
                }

            })
        }
    }

    return (
        <Container>
            <KlikHeader title="Règlement" back={"/book/resume"} />
            <KlikCard color="primary">
                <CardContent>
                    {errorPayment
                        ?
                        <Box color='white' mb={6}>
                            <Typography variant="h1" align='center' color="inherit">Echec du paiement</Typography>
                            <Button component={Link} to="/">Revenir à l'accueil</Button>
                        </Box>
                        :
                        <CardPaymentForm
                            subtitle={`Vous allez payer la somme de ${pricing?.finalPrice}€.`}
                            onPayment={proceedToPayment}
                            buttonLabel="Payer"
                            loading={loading} />
                    }
                </CardContent>
            </KlikCard>
        </Container>
    );
}

export default BookPayment;
