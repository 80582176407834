import React, { useContext } from 'react'

import { Container, Typography, Button, Box } from '@material-ui/core';

import { useHistory } from 'react-router-dom';

import ReturnContext from '../../contexts/ReturnContext';

import KlikHeader from '../../components/organisms/KlikHeader'
import KlikCode from '../../components/molecules/KlikCode'

const ReturnCode = () => {

	const { reservation } = useContext(ReturnContext)!;

	const history = useHistory();

	const lockCode = reservation?.code ?? ''

	const next = () => {
		history.push(`/return/${reservation.id}/inventory`)
	}

	return (
		<Container>			
			<KlikHeader
				back="/reservations"
				title="Restitution de la remorque" />
			<Box
				display="flex"
				flexDirection="column"
  				justifyContent="center"
  				alignItems="center">
				<Box mt={7} mb={4} width="260px">
					<Typography align="center" variant="h1">Voici le code de votre cadenas</Typography>
				</Box>
				<Box width="290px">
					<Typography align="center">Veuillez attacher la remorque au point d’attache et verrouiller le cadenas par la suite</Typography>	
				</Box>
				<Box mt={9} mb={12}>
					<KlikCode value={lockCode} />
				</Box>
				<Box>
					<Button
						variant="outlined" 
						color="primary"
						onClick={next}>J’ai bien verrouillé le cadenas</Button>
				</Box>
			</Box>
		</Container>
	);
};

export default ReturnCode;