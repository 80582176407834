import React from 'react';
import {Typography, Box} from '@material-ui/core';
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';

import { DateTime } from 'luxon'

const useStyles = makeStyles({
    textLabel: {
        fontSize: 10
    },
    firstLabel: {
        fontWeight: 700,
        fontSize: 10
    }
});

const useToogleButtonGroupStyles = makeStyles({
    root: {
        background: '#ECECEC',
        borderRadius: '15px'
    },
    groupedHorizontal: {
        '&:not(:first-child)': {
          marginLeft: 0,
          borderLeft: 'none',
          borderTopLeftRadius: 15,
          borderBottomLeftRadius: 15,
        },
        '&:not(:last-child)': {
          borderTopRightRadius: 15,
          borderBottomRightRadius: 15,
        },
    }
});

const useToogleButtonStyles = makeStyles({
    root: {
        padding: '6px 16px',
        margin: '2px',
        border: 'none',
        textTransform: 'none',
        '&$selected': {
          backgroundColor: '#FFFFFF'
        },
        lineHeight: 'none',
        borderRadius: '15px',
    },
    selected: {
        boxShadow: '0px 0.5px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '15px',
    },
    label: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        lineHeight: 'none'
    },
});

type SlotPickerBoundProps = {
	value: string,
	start: DateTime|undefined,
	end: DateTime|undefined
}

const SlotPickerBound = ({value, start, end} : SlotPickerBoundProps) => {

	const toogleButtonGroupClasses = useToogleButtonGroupStyles();
    const toogleButtonClasses = useToogleButtonStyles();
    const classes = useStyles();

	return (
        <Box display="flex" justifyContent="center">
            <ToggleButtonGroup 
                value={value}
                exclusive 
                classes={toogleButtonGroupClasses}>
                <ToggleButton 
                    value="start" 
                    classes={toogleButtonClasses}
                    disabled>
                    <Typography 
                        className={classes.firstLabel}>Début</Typography>
                    <Typography 
                        className={classes.textLabel}>{start !== undefined ? start.toLocaleString({ weekday: 'short', month: 'short', day: 'numeric', hour: '2-digit', minute:'2-digit'}) : "Quand ?"}</Typography>
                </ToggleButton>
                <ToggleButton 
                    value="end" 
                    classes={toogleButtonClasses}
                    disabled>
                    <Typography 
                        className={classes.firstLabel}>Fin</Typography>
                    <Typography 
                        className={classes.textLabel}>{end !== undefined ? end.toLocaleString({ weekday: 'short', month: 'short', day: 'numeric', hour: '2-digit', minute:'2-digit'}) : "Quand ?"}</Typography>
                </ToggleButton>
           </ToggleButtonGroup>
        </Box>
	);
}

export default SlotPickerBound