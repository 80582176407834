import React from 'react'

import { Box, CircularProgress, Container, Grid, makeStyles, Typography, Button } from '@material-ui/core'

import { useAuth } from '../../contexts/UserContext'

import ReservationCard from '../../components/layouts/ReservationCard'

import { Reservation } from '../../models/Reservation'

import { useReservationList } from '../../services/firebase'

import KlikHeader from '../../components/organisms/KlikHeader'

/**
 * Composant affichant la liste des réservations
 */
const Reservations = () => {

    const { user } = useAuth()
    const useStyles = makeStyles({
        subtitle: {
            lineHeight: '26.95px',
            maxWidth: '300px',
            textAlign: 'center',
            marginInline: 'auto',
            color: '#606060',
            fontSize: '15px',
            marginTop: '60px'
        },
        button: {
            marginTop: '25px'
        }
    });
    const classes = useStyles();

    let { error, loading, reservations } = useReservationList(user!.uid)
    
    const refresh = () => {
		window.location.reload()
        //TODO: Change for a react reservationList update
	}
    return (
        <Container>
            <KlikHeader title="Mes réservations" account back="/" />
            {loading && <Box width='100%' height="150px" display='flex' justifyContent='center' alignItems='center'>
                <CircularProgress color="secondary" />
            </Box>}
            {!loading && !error &&
                <div>{reservations.length !== 0 ?
                    <Grid container spacing={3}>
                        {reservations
                            .sort((a, b) => b.startDate.getTime() - a.startDate.getTime())
                            .map((reservation: Reservation) =>
                                <Grid key={reservation.id} item xs={12}>
                                    <ReservationCard
                                        reservation={reservation}
                                        partner={reservation.partner}
                                        vehicule={reservation.vehicule!} />
                                </Grid>
                            )}
                    </Grid>
                    :
                <Box textAlign="center">
                <Typography className={classes.subtitle}>Vous n'avez encore passé aucune réservation</Typography>
                <Button className={classes.button} onClick={refresh} color="default" variant="contained">Rafraichir la page</Button></Box>}</div>
            }
            {error && <div>Error message</div>}
        </Container>
    )
}

export default Reservations
