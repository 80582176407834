import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from '@material-ui/core'
import { db } from '../../services/firebase'
import { Referential } from '../../models/Referential'

const useStyles = makeStyles(() => ({
    footer: {
        fontSize: '12px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 'auto'
    }
}))

export const TermsAndConditions = ({ component, className, ...rest }: any) => {
    const classes = useStyles()

    const [terms, setTerms] = useState("");


    useEffect(() => {
        // Récupération du lien des conditions générales
        db.collection('referential').get().then((querySnapshot: any) => {
            const referential: Referential = querySnapshot.docs[0].data();
            setTerms(referential.terms);
        })
    }, [terms]);

    return <div className={classes.footer}>
        <Link className={classes.footer} href={terms}>Consulter les conditions générales</Link>
    </div>

}