const resources = {
  fr: {
    translation: {
      "home": {
        "action" : {
          "rent" : "Louer une remorque",
          "show" : "Mes réservations"
        }
      },
      "return": {
        "title" : "Voici le code de votre cadenas",
        "subtitle" : "Veuillez attacher la remorque au point d’attache et verrouiller le cadenas par la suite",
        "action" : "J'ai bien verrouillé le cadenas",
      }
    }
  }
};

export default resources;