import React from 'react'

import {Reservation} from '../models/Reservation'

const CancelContext = React.createContext<CancelContextType | undefined>(undefined)

type CancelContextType = {
    reservation: Reservation
};

export default CancelContext