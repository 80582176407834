import React, { useState } from 'react'
import { Box, Chip, Container } from '@material-ui/core'

import { useAuth } from '../../contexts/UserContext'
import KlikHeader from '../../components/organisms/KlikHeader'
import BookingUserProfile from '../../components/layouts/ProfileInfo/BookingUserProfile'
import { db } from '../../services/firebase'
import { useHistory } from 'react-router-dom'
import BookingLegalInformations from '../../components/layouts/ProfileInfo/BookingLegalInformations'

enum steps {
    UserProfile,
    LegalInformations
}

function AuthProfileEdit() {

    const history = useHistory()

    const { profile, user } = useAuth()!
    const [step, setStep] = useState(steps.UserProfile)

    const updateProfile = async (data: {}) => {
        const userRef = db.collection('users').doc(user!.uid)
        await userRef.update(data)
        setStep(steps.LegalInformations)
    }

    const updateLegalInformations = async (data: {}) => {
        const userRef = db.collection('users').doc(user!.uid)
        await userRef.update(data)
        history.push(`/auth/profile`)
    }

    const render = () => {
        switch (step) {
            case steps.UserProfile:
                return <BookingUserProfile onSubmit={updateProfile} profile={profile}/>
            case steps.LegalInformations:
                return <BookingLegalInformations onSubmit={updateLegalInformations} loading={false} profile={profile}/>
            default:
                console.warn('Unhandled case on booking steps.')
                return null
        }
    }

    return (
        <Container>
            <KlikHeader title="Modification des informations personnelles" />
            <Box mb={3} display="flex" justifyContent="center">
                <Chip label={'Étape ' + Number(step+1) + '/2'}/>
            </Box>
            {profile && render()}

        </Container>
    )
}

export default AuthProfileEdit