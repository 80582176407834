import React from 'react';
import ReactDOM from 'react-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';

import './index.css';
import App from './App';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import './services/i18n';
import './services/firebase';

Sentry.init({
  dsn: "https://292e5eaa8c9f49daab636203607c59d9@o1289990.ingest.sentry.io/6509657",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
  	<ThemeProvider theme={theme}>
    	<App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);