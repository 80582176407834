import React from 'react'

import UnlockContext from '../../contexts/UnlockContext'

import {Redirect, Route, Switch, useParams, useRouteMatch} from 'react-router-dom'

import {CircularProgress} from '@material-ui/core'

import UnlockConfirmation from './UnlockConfirmation'
import UnlockCondition from './UnlockCondition'
import UnlockCheck from './UnlockCheck'
import UnlockCode from './UnlockCode'

import {useReservation} from '../../services/firebase'

const Unlock = () => {
    const {path, url} = useRouteMatch()
    const params: any = useParams()
    const {error, loading, reservation} = useReservation({
        id: params.id
    })

    const ctx = {reservation: reservation!}

    return (
        <UnlockContext.Provider value={ctx}>
            {loading && <CircularProgress/>}
            {reservation !== undefined &&
            <Switch>
                <Route exact path={`${path}/`}>
                    <Redirect to={`${url}/confirmation`}/>
                </Route>
                <Route path={`${path}/confirmation`} component={UnlockConfirmation}/>
                <Route path={`${path}/condition`} component={UnlockCondition}/>
                <Route path={`${path}/check`} component={UnlockCheck}/>
                <Route path={`${path}/code`} component={UnlockCode}/>
            </Switch>}
            {error && <div>Error message</div>}
        </UnlockContext.Provider>
    )
}

export default Unlock


