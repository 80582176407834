import React from 'react'

import { Container, Typography, Grid, Button } from '@material-ui/core'

import { useHistory } from 'react-router-dom'

import KlikHeader from '../../components/organisms/KlikHeader'

/**
 * Composant terminant l'annulation
 **/
function CancelDone() {

    const history = useHistory()

    const next = () => {
        history.push(`/reservations`)
    }

    return (
      <Container>
      <KlikHeader title="Annulation de la réservation"/>
      <Grid item xs={12}>
        <Typography variant="subtitle1" align="center">{"La réservation a été annulée, vous avez bien été remboursé(e)."}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={next}>Retour à mes réservations</Button>
      </Grid>
    </Container>
    )
}

export default CancelDone