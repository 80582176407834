import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { Elements } from '@stripe/react-stripe-js'

// Providers
import { stripe } from './services/stripe'

// Contexts
import { UserProvider } from './contexts/UserContext'

// Components
import { PrivateRoute } from './components/atoms/PrivateRoute'
// import { Carbonara } from './components/atoms/Carbonara'

// Pages
import Home from './pages/Home'

import PartnerSearch from './pages/partner/PartnerSearch'
import PartnerList from './pages/partner/PartnerList'

import AuthLogin from './pages/auth/Login'
import AuthProfile from './pages/auth/Profile'
import Reservations from './pages/auth/Reservations'

import Book from './pages/book/Book'

import Unlock from './pages/unlock/Unlock'

import Return from './pages/return/Return'

import { BookingProvider } from './contexts/BookingContext'
import { makeStyles } from '@material-ui/core/styles'
import AuthProfileEdit from './pages/auth/ProfileEdit'
import Update from './pages/update/Update'
import Cancel from './pages/cancel/Cancel'
import { TermsAndConditions } from './components/atoms/TermsAndConditions'
import { Box } from '@material-ui/core'
import Receipt from './pages/auth/Receipt'

/**
 * Composant global
 */

const useStyles = makeStyles(theme => ({
    footer: {
        display: 'block',
        margin: 'auto'
    }
}))

const App = () => {
    const classes = useStyles()

    return (
        <>
        <Elements stripe={stripe}>
            <Router>
                <UserProvider>
                    <BookingProvider>
                        <Switch>
                            <Route path="/login" component={AuthLogin}/>
                            <PrivateRoute path="/auth/profile/edit" component={AuthProfileEdit}/>
                            <PrivateRoute path="/auth/profile" component={AuthProfile}/>
                            <PrivateRoute path='/reservations' component={Reservations}/>

                            <Route path="/book" component={Book}/>

                            <Route path="/partners/:id" children={<PartnerList/>}/>

                            <PrivateRoute path="/return/:id" component={Return}/>
                            <PrivateRoute path="/unlock/:id" component={Unlock}/>
                            <PrivateRoute path="/cancel/:id" component={Cancel}/>
                            <PrivateRoute path="/update/:id" component={Update}/>
                            <PrivateRoute path="/receipt/:id" component={Receipt}/>

                            <Route path="/partners" component={PartnerSearch}/>

                            <Route path="/" component={Home}/>
                        </Switch>
                    </BookingProvider>
                </UserProvider>
            </Router>
        </Elements>
        <Box className={classes.footer}>
                {/* <Carbonara></Carbonara> */}
                <TermsAndConditions></TermsAndConditions>
        </Box>
        </>
    )
}

export default App