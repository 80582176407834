import React, {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {Box, Button, Container, Grid, Typography} from '@material-ui/core'
import KlikCode from '../../components/molecules/KlikCode'
import UnlockContext from '../../contexts/UnlockContext'
import KlikHeader from '../../components/organisms/KlikHeader'

/**
 * Composant affichant le code du cadenas
 */
function UnlockCode() {
    const history = useHistory()
    const {reservation} = useContext(UnlockContext)!

    const lockCode = reservation.code ?? ''

    const next = () => {
        history.push(`/`)
        history.push({
            pathname: "/",
            state: {
                hasBeenUnlocked: true
            }
        })
    }

    return (
        <Container>
            <KlikHeader title="Code cadenas"/>
            <Box mt={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Typography align='center' variant="h1">Voici le code de votre cadenas</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <KlikCode value={lockCode}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mx='auto' width="max-content">
                            <Button variant="contained" color="primary" onClick={next}>Retour à l'accueil</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default UnlockCode