import * as firebase from 'firebase'
import 'firebase/storage';
import { useEffect, useState } from 'react'


import { Reservation } from '../models/Reservation'
import { Partner } from '../models/Partner'
import { ResponsePrice } from '../models/ResponsePrice'
import { VehiculeType } from '../models/VehiculeType'
import { Vehicule } from '../models/Vehicule'

type FirestoreDataConverter<T> = firebase.firestore.FirestoreDataConverter<T>

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    //messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
}

// Initialisation de Firebase
const app = firebase.initializeApp(firebaseConfig)

export const auth = app.auth()
auth.languageCode = 'fr'

export const db = app.firestore()
export const functions = app.functions('europe-west1')
export const storage = app.storage()

// eslint-disable-next-line no-restricted-globals
// if (location.hostname === 'localhost') {
//     firebase.firestore().settings({ host: 'localhost:8080', ssl: false });
//     functions.useFunctionsEmulator('http://localhost:5001');
//     firebase.auth().useEmulator("http://localhost:9099"); // Connect to the Authentication emulator
// }

// var testtt = firebase.firestore();
// if (location.hostname === "localhost") {
//     testtt.useEmulator("localhost", 8080);
// }

export const useReservationList = (userId: string) => {
    const [error, setError] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [reservations, setReservations] = useState<Array<Reservation>>([])

    useEffect(() => {
        (async () => {
            let result: Array<Reservation> = []
            const reservationsDoc = await db.collection('reservations').where('userId', '==', userId).get()

            reservationsDoc.forEach((doc) => {
                const data = doc.data()
                result.push({
                    id: doc.id,
                    startDate: data.startDate.toDate(),
                    endDate: data.endDate.toDate(),
                    partnerId: data.partnerId,
                    userId: data.userId,
                    vehiculeId: data.vehiculeId,
                    hasBeenUnlocked: data.hasBeenUnlocked,
                    hasBeenReturned: data.hasBeenReturned,
                    paymentIntentId: data.paymentIntentId,
                    pricing: data.pricing,
                    updated: data.updated,
                    code: data.code
                } as Reservation)
            })

            await Promise.all(result.map(async (reservation: Reservation) => {
                const partnerDoc = await db.collection('partners').doc(reservation.partnerId).get()
                const vehiculeDoc = await db.collection('vehicles').doc(reservation.vehiculeId).get()
                let vehicule = vehiculeDoc.data() as Vehicule
                if (vehicule) {
                    vehicule.id = reservation.vehiculeId
                    const typeDoc = await db.collection('vehicleTypes').doc(vehicule.type).get()
                    vehicule.fulltype = typeDoc.data() as VehiculeType
                    vehicule.fulltype.url = await storage.ref()
                        .child('referential/img/' + vehicule.fulltype.imageLabel + '.jpg')
                        .getDownloadURL()
                    reservation.vehicule = vehicule
                }
                else {
                    // Quick dirty fix, Fake data for old Remorques to avoid crash
                    // TODO: Add proper data when the remorque doesn't exists anymore
                    reservation.vehicule = {
                        id: "0",
                        label: "retirée",
                        reservations: [],
                        type: "",
                        fulltype: {
                            id: "0",
                            imageLabel: "1",
                            label: "Remorque ouverte"
                        },
                        lockId: "0"
                    }
                }
                if (partnerDoc.exists) {
                    reservation.partner = partnerDoc.data() as Partner
                }
            }))

            setReservations(result)
            setLoading(false)
            setError(false)
        })()

        

    }, [userId])

    return { error, loading, reservations }
}

type usePricingProps = {
    startDate: string,
    endDate: string,
    partnerId: string,
    withInsurance: boolean,
    discountKey: string,
    discountCode: string
}

export const usePricing = ({ startDate, endDate, partnerId, withInsurance, discountKey, discountCode }: usePricingProps) => {

    const [error, setError] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [pricing, setPricing] = useState<ResponsePrice>()

    useEffect(() => {
        setLoading(true)
        const getReservationPrices = functions.httpsCallable('getReservationPriceFull')

            ; (async () => {
                if (startDate !== null) {
                    const result = await getReservationPrices({ startDate: startDate, endDate: endDate, partnerId, withInsurance, [discountKey]: discountCode })
                    setPricing(result.data)
                    setLoading(false)
                    setError(false)
                }

            })()
    }, [partnerId, startDate, endDate, withInsurance, discountKey, discountCode])

    return { error, loading, pricing }
}

function documentRef<T>(collection: string, doc: string, converter?: FirestoreDataConverter<T>) {
    const documentReference = db.collection(collection).doc(doc)

    if (converter) return documentReference.withConverter(converter)

    return documentReference
}

/**
 * Hook permettant d'accéder au document par son id
 */
export const useDocument = <T>({ collection, doc, converter }: useDocumentProps<T>) => {
    const [error, setError] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [result, setResult] = useState<T | undefined>()

    useEffect(() => {
        setLoading(true)
        return documentRef(collection, doc, converter)
            .onSnapshot((snap) => {
                setLoading(false)
                setResult(snap.data() as T)
            }, () => setError(true))
    }, [collection, converter, doc])

    return { error, loading, result }
}

type useDocumentProps<T> = {
    collection: string,
    doc: string,
    converter: FirestoreDataConverter<T> | undefined,
}

/* 

Todo : useCollection

*/

const reservationConverter = {
    toFirestore(reservation: Reservation): firebase.firestore.DocumentData {
        return {
            startDate: reservation.startDate,
            endDate: reservation.endDate,
            userId: reservation.userId,
            partnerId: reservation.partnerId,
            vehiculeId: reservation.vehiculeId,
            hasBeenUnlocked: reservation.hasBeenUnlocked,
            hasBeenReturned: reservation.hasBeenReturned,
            paymentIntentId: reservation.paymentIntentId,
            pricing: reservation.pricing,
            updated: reservation.updated
        }
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
    ): Reservation {
        const data = snapshot.data(options)!
        return new Reservation(
            snapshot.id,
            data.startDate.toDate(),
            data.endDate.toDate(),
            data.userId,
            data.partnerId,
            data.vehiculeId,
            data.pricing,
            data.code,
            data.hasBeenUnlocked,
            data.hasBeenReturned,
            data.paymentIntentId,
            data.updated
        )
    }
}

type useReservationProps = {
    id: string
}

type useReservationReturn = {
    error: boolean,
    loading: boolean,
    reservation: Reservation | undefined
}

/**
 * Hook permettant d'accéder au document par son id et retourner une réservation
 */
export const useReservation = ({ id }: useReservationProps): useReservationReturn => {
    const { error, loading, result } = useDocument({
        collection: 'reservations',
        doc: id,
        converter: reservationConverter,
    })
    return { error, loading, reservation: result }
}


export const useLicenceUrl = (drivingLicensePictureId?: string) => {
    const [url, setUrl] = useState<string | undefined>()
    useEffect(() => {
        if (!drivingLicensePictureId) return

        storage.ref().child('drivingLicense').child(drivingLicensePictureId)
            .getDownloadURL()
            .then((url) => {
                setUrl(url)
            })
    }, [drivingLicensePictureId])

    return url
}
