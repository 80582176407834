import { Checkbox, FormControl, FormControlLabel, Box, Container, Button, Typography, Link } from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';

import KlikHeader from '../../components/organisms/KlikHeader';

import { useHistory } from 'react-router-dom';

import UnlockContext from '../../contexts/UnlockContext';
import { db } from '../../services/firebase'
import { Referential } from '../../models/Referential';


/**
 * Composant affichant les CGU/CGV
 */

function UnlockCondition() {

    const { reservation } = useContext(UnlockContext)!;

    const history = useHistory();

    const [useConditionsValidated, setUseConditionsValidated] = useState(false);
    const [commercialConditionsValidated, setCommercialConditionsValidated] = useState(false);
    const [terms, setTerms] = useState("");


    useEffect(() => {
        // Récupération du lien des conditions générales
        db.collection('referential').get().then((querySnapshot: any) => {
            const referential: Referential = querySnapshot.docs[0].data();
            setTerms(referential.terms);
        })
    }, [terms]);

    const toggleUseConditions = () => setUseConditionsValidated(!useConditionsValidated);
    const toggleCommercialConditions = () => setCommercialConditionsValidated(!commercialConditionsValidated);

    const next = () => {
        if (reservation != null) {
            history.push(`/unlock/${reservation.id}/check`);
        }
    }

    return (
        <Container>
            <KlikHeader title="Conditions de location &#x26; d’assurance" back="/unlock" />
            <Typography>Veuillez prendre connaissance des conditions générales et les accepter :</Typography>
            <Link href={terms}>Consulter les conditions générales</Link>
            <FormControl component="fieldset" className="conditionsForm">
                <FormControlLabel
                    control={<Checkbox />}
                    label="J'accepte les conditions générales d'utilisation"
                    checked={useConditionsValidated}
                    onChange={toggleUseConditions}
                />
                <FormControlLabel
                    control={<Checkbox />}
                    label="J'accepte les conditions générales de location et d'assurance"
                    checked={commercialConditionsValidated}
                    onChange={toggleCommercialConditions}
                />
            </FormControl>
            <Box display="flex" justifyContent="center" mt={3} mb={4}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!(useConditionsValidated && commercialConditionsValidated)}
                    onClick={next}>Valider</Button>
            </Box>
        </Container>
    );
}

export default UnlockCondition;