import React, { useState } from 'react';
import 'firebase/auth';
import { Link } from 'react-router-dom';
import { Button, Grid, Typography, Box, Container } from '@material-ui/core';

import { ReactComponent as CheckIcon } from '../../assets/img/CheckIcon.svg';

import { makeStyles } from '@material-ui/core/styles';

import KlikHeader from '../../components/organisms/KlikHeader'
import { useEffect } from 'react';

// A style sheet
const useStyles = makeStyles({
    subtitle: {
        lineHeight: '26.95px',
        maxWidth: '300px',
        textAlign: 'center',
        marginInline: 'auto',
        color: '#606060',
        fontSize: '23px',
        marginTop: '18px',
        marginBottom: '18px'
    }
});

/**
 * Page de confirmation de réservation
 */
const UpdateEnd = () => {

    const classes = useStyles()

    const [hasBeenRefunded, setHasBeenRefunded] = useState()

    useEffect(() => {
        setHasBeenRefunded(JSON.parse(localStorage.getItem("hasBeenRefunded")!))
    }, [])

    return (
        <Container>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
                <KlikHeader />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box maxWidth="106px" mx="auto">
                            <CheckIcon />
                        </Box>
                    </Grid>
                    { hasBeenRefunded && <Grid item xs={12}>
                        <Typography variant="subtitle1" className={classes.subtitle}>Le remboursement a bien été effectué !</Typography>
                    </Grid>}
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" className={classes.subtitle}>La modification de votre réservation a été prise en compte.</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Button component={Link} to="/reservations" fullWidth variant="contained" color="primary">Voir mes réservations</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button component={Link} to="/">Revenir à l'accueil</Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
}

export default UpdateEnd