import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import {useAuth} from '../../contexts/UserContext'

export const PrivateRoute = ({component, ...rest}: any) => {

    const {authenticated: isAuthenticated} = useAuth()

    const routeComponent = (props: any) => (
        isAuthenticated
            ? React.createElement(component, props)
            : <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
    )
    return <Route {...rest} render={routeComponent}/>
}